
import { Injectable } from '@angular/core';
//import { Http, HttpModule, Headers, Response } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
//import { BrowserModule } from '@angular/platform-browser';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { HttpResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatRadioModule, MatRadioButton } from '@angular/material';

import {
  ActionAuthLogin,
  ActionAuthLogout
} from '@app/core';
import { validateBasis } from '@angular/flex-layout';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/ConfirmDialog';
import { IConfirmResponse } from '../../core/interfaces/confirm/IConfirmResponse';
import { promise } from 'protractor';

@Injectable()
export class AuthenticationService {
  public token: string;
  private userString: string;
  private apiBaseUrl: string;
  private apiAuthUrl: string;
  private appId: number;
  private httpOptions: any;
  private authGuardService: AuthGuardService;
  public userData: any;
  private accountCompletionSettings: any = { orgNumber: null, email: null, response: null };
  public userDataIsUpdated: boolean = false;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    authGuardService: AuthGuardService,
    private localStorageService: LocalStorageService,
    //private organizationService: OrganizationService,
    private store: Store<any>,
    private router: Router,
    public snackBar: MatSnackBar) {

    this.authGuardService = authGuardService;
    this.setHttpOptions();
    var userData = JSON.parse(localStorage.getItem('ANMS-AUTH'));

    if (userData != undefined && userData.token != undefined) {
      this.token = userData.token.access_token;
      this.userData = userData

      this.checkForAccountCompletion();
    }

    this.apiBaseUrl = env.hostApi;
    this.apiAuthUrl = env.hostAuth;
    this.appId = env.appId;
  }

  ngOnInit(): void {
  }

  private subject = new Subject<any>();

  userUpdated(message: string) {
    this.subject.next({ text: message });
  }

  getUserData(): Observable<any> {
    return this.subject.asObservable();
  }

  login(username: string = null, password: string = null, bankIdpersonalNumber: string = null, emailEncrypted: string = null, userId: string = null, pid: string = null, appId: number = null): Observable<boolean> {

    let data = "grant_type=password&username=" + username + "&password=" + password;
    var url = this.apiAuthUrl;

    if (bankIdpersonalNumber != null)
      url = url + "?bankIdpersonalNumber=" + bankIdpersonalNumber

    if (bankIdpersonalNumber != null)
      url = url + "&emailEncrypted=" + emailEncrypted

    if (pid != null)
      url = url + "&pid=" + pid

    if (appId != null)
      url = url + "&appid=" + appId

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      })
    };

    return this.http.post<any>(url, data, httpOptions)
      .pipe(
        tap((response: any) =>
          this.saveToken(response, username)),
        catchError(this.handleError('login', data)
        ));
  }

  loginBankIdPassive(personalNumber: string, userId: string = null): Observable<boolean> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    var url = this.apiBaseUrl + "BankId/" + personalNumber + "/AuthenticatePassive";

    if (userId != null)
      url = url + "&userId=" + userId;

    return this.http.get<any>(url, httpOptions)
      .pipe(
        tap((response: any) =>
          this.saveToken(response, response.userName)),
        catchError(this.handleError('bankid_passive')
        ));
  }

  onUserLoggedIn(response: any) {
    this.saveToken(response, "");
  }

  createAccount(user: any): Observable<boolean> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    var data = JSON.stringify(user);

    return this.http.post<any>(this.apiBaseUrl + 'Users', data, httpOptions)
      .pipe(
        tap((response: any) =>
          console.log(response)
        ),
        catchError(this.handleError('login', "error"))
      );
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      let config = new MatSnackBarConfig();
      config.duration = 15000;

      console.log('Err:')
      console.log(error);

      if (error.status == 400) {
        this.snackBar.open("Fel användarnamn eller lösenord! \r\n\r\nOm du skapat ett konto via epost, logga in på det och ange personnummer för att koppla till BankID!", undefined, config);
      }
      else if (error.status == 409) {
        this.snackBar.open("Epostadressen är upptagen!", undefined, config);
      }
      else if (error.statusText == 'Unknown Error') {
        this.snackBar.open("Något gick fel. Försök gärna logga in igen, och kontakta support om problemet kvarstår.", undefined, config);
      }
      else if (error.status == 500) {
        this.snackBar.open(error.error.ExceptionMessage, undefined, config);
      }
      else {
        this.snackBar.open(error.error, undefined, config);
      }

      return of(result as T);
    };
  }

  getCurrentUser(): Observable<any> {

    var url = this.apiBaseUrl + 'User/My';
    var bearerToken = 'bearer ' + this.token;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': bearerToken
      })
    };
    
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onFinished(response)),
        catchError(
          (err: any) => {
            console.log(err)
            return new Observable<any>();

          }
        ));
  }

  searchOrganizationById(id: string): Observable<any> {

    var url = this.apiBaseUrl + 'Organizations/Search/' + id;
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onRetrieved(response)),
        catchError(
          (err: any) => {
            return new Observable<any>();
          }
        ));
  }

  onRetrieved(response: any) {
    console.log(response);
  }


  checkForAccountCompletion(): void {

    setTimeout(() => {
      const url = this.apiBaseUrl + 'Users/Me/NeedsCompletion';

      this.http.get<any>(url, this.httpOptions).subscribe(
        (response) => {
          this.handleAccountCompletion(response);
        },
        (error) => {
          console.error('Error occurred during HTTP call:', error);
        }
      );
    }, 1500);
  }

  updateCurrentUserData(email: string, organizationNumber: string = null, sampoolenIsMainEmployer: string = null, appId: string = "2"): void {
    debugger
    email = email == null ? '' : email;
    organizationNumber = organizationNumber == null ? '' : organizationNumber;
    sampoolenIsMainEmployer = sampoolenIsMainEmployer == null ? '' : sampoolenIsMainEmployer;

    const url = this.apiBaseUrl + 'Users/Me';
    var bearerToken = 'bearer ' + this.token;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': bearerToken
      })
    }; 
    var sampoolenIsMainEmployerBool = sampoolenIsMainEmployer.toLowerCase() == "ja" ? true : false;
    var payload = JSON.parse('{"SystemAppId": "' + appId + '", "Email": "' + email + '", "OrganizationNr": "' + organizationNumber + '", "SampoolenIsMainEmployer": "' + sampoolenIsMainEmployer + '"}');

    this.http.patch<any>(url, payload, this.httpOptions).subscribe(
      (response) => {
        debugger
        this.userDataUpdated(response);
      },
      (errorResponse) => {
        debugger
        this.handleAccountCompletion(null, errorResponse.error.Message)
      }
    );

  }

  async handleAccountCompletion(response, errorMessage = null): Promise<void> {
    try { 
      // Set the completioninfo for later use if function is calles again
      if (response != null)
        this.accountCompletionSettings.response = response;

        
      var errorType = errorMessage != null && errorMessage.includes("Epostadressen") ? "email" : null;
      
      if (this.accountCompletionSettings.response.NeedsCompletion) {
        
        if ((this.accountCompletionSettings.response.CompleteEmail && this.accountCompletionSettings.userEmail == null) || errorType == "email")
          this.accountCompletionSettings.userEmail = await this.openGetUserEmailModal(errorMessage);

        if (this.appId == 2 && (this.accountCompletionSettings.response.CompleteOrganization && this.accountCompletionSettings.orgNumber == null) || errorType == "orgNumber")
          this.accountCompletionSettings.orgNumber = await this.openGetOrganizationnumberModal();

        if (this.appId == 1 && ((this.accountCompletionSettings.response.CompleteSampoolenIsMainEmployer && this.accountCompletionSettings.sampoolenIsMainEmployer == null) || errorType == "employer")) {
          this.accountCompletionSettings.sampoolenIsMainEmployer = await this.openGetEmployerModal();
        }
          
        // Sampoolen
        if (this.appId == 1) {
          
          if (this.accountCompletionSettings.sampoolenIsMainEmployer != null) {
            this.updateCurrentUserData(null, null, this.accountCompletionSettings.sampoolenIsMainEmployer, this.appId.toString());
          }
        }
        // Samfinans
        else if(this.appId == 2) {

          var emailValidates = !this.accountCompletionSettings.response.CompleteEmail || (this.accountCompletionSettings.response.CompleteEmail && this.accountCompletionSettings.userEmail != null)
          var organizationValidates = !this.accountCompletionSettings.response.CompleteOrganization || (this.accountCompletionSettings.response.CompleteOrganization && this.accountCompletionSettings.orgNumber != null)

          //Only update if email or organization is set
            if (this.accountCompletionSettings.userEmail != null || this.accountCompletionSettings.orgNumber != null) {
                if (emailValidates && organizationValidates) {
                    
                    this.updateCurrentUserData(this.accountCompletionSettings.userEmail, this.accountCompletionSettings.orgNumber, this.accountCompletionSettings.sampoolenIsMainEmployer, this.appId.toString());
                }
            }
        }

      }




    } catch (error) {
      // Handle any error that occurred during the modal operations
      alert('An error occurred during account completion.');
    }
  }

  async openGetUserEmailModal(errorMessage = null): Promise<string> {

    return new Promise((resolve, reject) => {

      // Trigger modal to fill email address
      var config = JSON.parse(JSON.stringify(this.config));
      config.height = 310;
      config.width = 400;
      config.data.hideInput = false;
      config.data.requireInput = false;
      config.data.hideCancelButton = true;
      config.data.inputTitle = 'Ange din epostadress';
      config.data.confirmButtonText = "Spara!";
      config.data.confirmButtonIcon = "check";      
      config.data.errorText = errorMessage;

      if (this.appId == 1) {
        config.data.headline = "Hej! Välkommen till SAMpoolen";
        config.data.text = "Ange din epostadress så kan vi starta igång sedan.";

      }
      if (this.appId == 2) {
        config.data.headline = "Hej! Välkommen till SAMekonomi";
        config.data.text = "Vi ser att det är första gången du besöker oss. Låt oss börja med att skapa ditt personkonto:";
      }
            
      let dialogRef = this.dialog.open(ConfirmDialogComponent, config);

      dialogRef.afterClosed().subscribe((result: IConfirmResponse) => {
        dialogRef = null;

        if (result.Confirmed) {
          if (!this.validateEmail(result.ResponseText)) {
            reject("Ange en giltig epostadress!");
          }
          else {
            resolve(result.ResponseText);
          }
        }
        else {
          reject("Vänligen ange en annan epostadress!");
        }

      });

    });

  }

  openGetOrganizationnumberModal(errorMessage: string = null): Promise<string> {
    return new Promise((resolve, reject) => {
      var config = JSON.parse(JSON.stringify(this.config));
      config.height = 310;
      config.width = 400;
      config.data.hideInput = false;
      config.data.requireInput = false;
      config.data.hideCancelButton = true;
      config.data.inputTitle = 'Organisationsnummer';
      config.data.headline = "Organisation";
      config.data.confirmButtonText = "Spara!";
      config.data.confirmButtonIcon = "check";
      config.data.text = "Tack. Då är ditt personkonto klart. Vilket bolag vill du koppla? Ange org.nr så ser vi om vi hittar det.";
      config.data.errorText = errorMessage;

      let dialogRef = this.dialog.open(ConfirmDialogComponent, config);

      dialogRef.afterClosed().subscribe(async (result: IConfirmResponse) => {
        dialogRef = null;

        if (result.Confirmed) {
          try {
            const companySearchResponse = await this.searchOrganizationById(result.ResponseText).toPromise();

            if (companySearchResponse.IsRegisteredInOrganization) {
              this.openGetOrganizationnumberModal('Företaget redan registrerat!').then(resolve).catch(reject);
            }
            else {
              const confirmOrganization = await this.openConfirmOrganizationModal(companySearchResponse.Name);

              if (confirmOrganization) {
                resolve(result.ResponseText);
              } else {
                this.openGetOrganizationnumberModal().then(resolve).catch(reject);
              }
            }

          } catch (error)
          {
            console.error(error);
            reject('Organization number search failed');
          }
        } else
        {
          reject('Organization number input was canceled');
        }

      });
    });
  }


  openGetEmployerModal(errorMessage: string = null): Promise<string> {
    return new Promise((resolve, reject) => {
      var config = JSON.parse(JSON.stringify(this.config));
      config.height = 400;
      config.width = 400;
      config.disableClose = false;
      config.data.hideInput = false;
      config.data.requireInput = false;
      config.data.hideCancelButton = true;
      config.data.inputTitle = 'Svarsalternativ: ja/nej';
      config.data.headline = "Arbetsgivare";
      config.data.useYesNo = true;
      config.data.confirmButtonText = "Spara!";
      config.data.confirmButtonIcon = "check";
      config.data.text = "Är Sampoolen din huvudsakliga arbetsgivare? <br><br>Huvudarbetsgivare är den arbetsgivare som du din huvudsakliga inkomst från. <br><br> Om du får lön från flera arbetsgivare under ett kalenderår, ska du svara ja <strong>endast</strong> om du får mer inkomst från oss än från någon annan arbetsgivare.";
      config.data.errorText = errorMessage;

      let dialogRef = this.dialog.open(ConfirmDialogComponent, config);

      dialogRef.afterClosed().subscribe(async (result: IConfirmResponse) => {
        dialogRef = null;

        if (result.Confirmed) {
          try {
            var response = result.ResponseText.toLowerCase();
            if (response == "ja" || response == "nej")
            {
              resolve(response);
            } else {
              this.openGetEmployerModal().then(resolve).catch(reject);
            }
            
          } catch (error) {
            console.error(error);
            reject('Employer input failed');
          }
        } else {
          reject('Employer input canceled');
        }

      });
    });
  }

  userDataUpdated(response) {

    this.userDataIsUpdated = true;

    // Trigger modal to fill email address
    var config = JSON.parse(JSON.stringify(this.config));
    config.height = 325;
    config.width = 400;
    config.data.hideInput = true;
    config.data.requireInput = false;
    config.data.hideCancelButton = true;
    config.data.headline = "Registrering färdig!";
    config.data.confirmButtonText = "Ok";
    config.data.confirmButtonIcon = "check";

    if (this.appId == 1) {
      config.data.text = "Tack för ditt förtroende. Tveka inte höra av dig om det dyker upp några frågor och/eller funderingar!";
    }
    if (this.appId == 2) {
      config.data.text = "Perfekt! Nu är både ditt personkonto samt företagets konto skapat. Tveka inte höra av dig om det dyker upp några frågor och/eller funderingar! Tack för ert förtroende!";
    }


    let dialogRef = this.dialog.open(ConfirmDialogComponent, config);

    dialogRef.afterClosed().subscribe((result: IConfirmResponse) => {
      dialogRef = null;

      this.getCurrentUser()
        .subscribe(
          data => {
            var userString = localStorage.getItem('ANMS-AUTH')
            var currentUserObject = JSON.parse(userString);
            currentUserObject.User = data;
            localStorage.setItem('ANMS-AUTH', JSON.stringify(currentUserObject));
            this.userData = currentUserObject;
          },
          error => {
            alert('err');
          });

    });

  }

  openConfirmOrganizationModal(orgName: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var config = JSON.parse(JSON.stringify(this.config));
      config.height = 310;
      config.width = 400;
      config.data.hideInput = true;
      config.data.requireInput = false;
      config.data.hideCancelButton = false;
      config.data.inputTitle = 'Bekräfta';
      config.data.headline = "Bekräfta organisation";
      config.data.confirmButtonText = "Ja";
      config.data.confirmButtonIcon = "check";
      config.data.text = "Är " + orgName + " rätt organisation?";

      let dialogRef = this.dialog.open(ConfirmDialogComponent, config);

      dialogRef.afterClosed().subscribe((result: IConfirmResponse) => {
        dialogRef = null;

        if (result.Confirmed) {
          resolve(true);
        } else {
          resolve(false); // Resolve with false indicating the user canceled the confirmation
        }
      });
    });
  }

  validateEmail(email: string): boolean {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex
    return emailRegex.test(email);
  }

  config = {
    disableClose: true,
    panelClass: 'full-screen-modal',
    hasBackdrop: true,
    backdropClass: '',
    width: '500px',
    maxWidth: '100vw',
    height: '100%',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      invoiceItemGroup: {},
      customerTemplate: {},
      contact: { IDNumber: null },
      companyId: 0,
      status: {},
      customerCountryName: null,
    },
    dataTemplate: {
      Id: 0,
      Country: { Id: 1 },
      Type: {},
      HoursWorked: undefined,
      StartDate: undefined,
      EndDate: undefined,
      Comment: undefined,
      InvoiceId: 0
    }
  };

  onFinished(response: any) {

    var userString = localStorage.getItem('ANMS-AUTH')
    var currentUserObject = JSON.parse(userString);
    this.userData = currentUserObject;
    
    currentUserObject.User = response;
    localStorage.setItem('ANMS-AUTH', JSON.stringify(currentUserObject));
    this.userUpdated("");

    if (!this.userDataIsUpdated)
    this.checkForAccountCompletion();
  }

  setHttpOptions() {
    var bearerToken = 'bearer ' + this.token;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': bearerToken
      })
    };
  }

  currentUserHasCoordinationNumber() {
    return this.userData.User.AccountSettings.HasCoordinationNumber;
  }

  currentUserUserId() {
    return this.userData.User.AccountSettings.Guid;
  }

  getCurrentUserOrganization() {
    return this.userData.User.AccountSettings.Organization;
  }

  setCurrentUserOrganizationCompany(company) {

    if (company == null)
      return;

    this.userData.User.AccountSettings.Organization.Company = company;

    var storedUserData = JSON.parse(localStorage.getItem('ANMS-AUTH'));
    storedUserData.User.AccountSettings.Organization.Company = company;
    localStorage.setItem('ANMS-AUTH', JSON.stringify(storedUserData));
  }

  currentUserIsLoggedInWithBankId() {
    return this.userData.User.LoginType === 'bankid';
  }

  currentUserHasPersonalNumberSet() {
    return this.userData.User.AccountSettings.PersonalNumber != null ? true : false;
  }

  saveToken(response: any, username: string) {

    // login successful if there's a jwt token in the response
    if (response) {
      // set token property
      this.token = response.access_token;
      this.store.dispatch(new ActionAuthLogin());

      // store username and jwt token in local storage to keep user logged in between page refreshes
      var userString = JSON.stringify({ isAuthenticated: true, username: username, token: response });
      localStorage.setItem('ANMS-AUTH', userString);
      var currentUserObject = JSON.parse(userString);

      this.token = currentUserObject.token.access_token;
      
      this.getCurrentUser()
        .subscribe(
          data => {
            var userString = localStorage.getItem('ANMS-AUTH')
            var currentUserObject = JSON.parse(userString);
            currentUserObject.User = data;
            localStorage.setItem('ANMS-AUTH', JSON.stringify(currentUserObject));
            this.userData = currentUserObject;
          },
          error => {
            alert('err');
          });

      return true;
    } else {
      // return false to indicate failed login
      return false;
    }
  }

  requestResetPassword(user: User, appId: number): Observable<boolean> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    var data = JSON.stringify(user);

    return this.http.post<any>(this.apiBaseUrl + 'Users/ForgotPassword?appId=' + appId, data, httpOptions)
      .pipe(
        tap((response: any) =>
          this.requestResetPasswordFinished()
        ),
        catchError(this.handleError('login', data)
        ));
  }

  requestResetPasswordFinished() {
    let config = new MatSnackBarConfig();
    config.duration = 7000;

    this.snackBar.open("E-postmeddelande skickat!", undefined, config);
    this.router.navigateByUrl("/login");
  }

  resetPassword(request: PasswordReset): Observable<boolean> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    var data = JSON.stringify(request);

    return this.http.post<any>(this.apiBaseUrl + 'Users/ResetPassword', data, httpOptions)
      .pipe(
        tap((response: any) =>
          this.resetPasswordFinished()
        ),
        catchError(this.handleError('resetpassword', data)
        ));
  }

  resetPasswordFinished() {
    let config = new MatSnackBarConfig();
    config.duration = 7000;

    this.snackBar.open("Lösenordet återställt!", undefined, config);
    this.router.navigateByUrl("/login");
  }

  logout(): void {
    this.token = "";
    localStorage.removeItem('currentUser');
    localStorage.removeItem('ANMS-AUTH');

    this.store.dispatch(new ActionAuthLogout());

    location.reload();
  }

  clearLoginStorage() {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.setItem("AUTH", "{ isAuthenticated: false }");
  }

  
}



@Injectable()
export class User {
  constructor
    (
      public Email: string,
      public PersonalNumber: string,
      public username: string,
      public password: string
    ) { }
}

@Injectable()
export class PasswordReset {
  constructor
    (
      public Email: string,
      public Password: string,
      public ConfirmPassword: string,
      public Code: string
    ) { }
}
