<style>
  .fix-static-bug {
    width: 100% !important;
    position: static !important;
  }

  .grey {
    color: #383838;
  }

  .padding30 {
    padding: 30px;
  }

  .link {
    float: right;
    margin-right: 20px;
    position: relative;
    top: 15px;
  }

  .infotext {
    padding-left: 15px;
    font-size: 80%;
    margin-top: -15px;
  }

  .person {
    float: right;
  }

  .infoicon {
    font-size: 16px;
    position: relative;
    top: 2px;
  }

  .light-grey {
    color: lightgrey;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2; /* Light grey color */
  }

</style>

<div class="fix-static-bug">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3 class="main-heading">In- och utbetalning</h3>
      </div>
    </div>

    <!--Calculate partial total amount / gross salary-->
    <div *ngIf="!isLoading" class="row align-items-end" style="background-color: #f3f3f3; padding-top: 20px; padding-bottom: 30px;">
      <div class="col-12">
        <h2>Beräkning av delsumma för bruttolön (inkl nettoersättningar)</h2>
      </div>
      <div *ngIf="invoice.SalaryShares != null && invoice.SalaryShares.length > 0" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns en lönedelning på denna faktura</b><br />
      </div>
      <div *ngIf="invoice?.UserSettings?.AttachmentOfSalaries.length != 0 && invoice?.UserSettings?.AttachmentOfSalaries.length > 0" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns en löneutmätning på medlemmen</b><br />
      </div>
      <div *ngIf="invoice.SubInvoice && invoice.ApprovedForExpress" class="col-12" style="margin-bottom: 10px;">
        <b style="color: green;">Moderfakturan är godkänd för expressutbetalning</b><br />
      </div>
      <div *ngIf="!invoice.SubInvoice && !invoice.ParentInvoice && invoice.ExpressPayOut && invoice.ApprovedForExpress" class="col-12" style="margin-bottom: 10px;">
        <b style="color: green;">Express begärd och fakturan godkänd</b><br />
      </div>
      <div *ngIf="!invoice.SubInvoice && !invoice.ParentInvoice && !invoice.ApprovedForExpress && invoice.ExpressPayOut" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Express begärd men ej godkänd</b><br />
      </div>
      <div *ngIf="!invoice.SubInvoice && !invoice.ParentInvoice && invoice.ApprovedForExpress && !invoice.ExpressPayOut" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Express ej, men fakturan godkänd</b><br />
      </div>
      <div *ngIf="invoice.HasUnhandledDeductions" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns ohanterade avdrag</b><br />
      </div>
      <div *ngIf="invoice?.UserSettings?.TaxAdjustments.length != 0 && invoice?.UserSettings?.TaxAdjustments.length > 0" class="col-12" style="margin-bottom: 10px;">
        <b *ngIf="!hasUnhandledTaxAdjustments" style="color: red;">Det finns en skattejämkning/skatteändring på medlemmen</b><br />
        <b *ngIf="hasUnhandledTaxAdjustments" style="color: red;">Det finns en <strong><u>ohanterad</u></strong> skattejämkning/skatteändring på medlemmen.</b>   <a href="#/users/{{invoice?.UserSettings?.Guid}}" target="_blank"> Medlemmens inställningar </a><br />
      </div>
      <div *ngIf="invoice?.AdminInfo != ''" class="col-12">
        <b style="color: red;">{{invoice?.AdminInfo}}</b><br />
      </div>
      <div *ngIf="invoice?.UserSettings?.AdminInfo != ''" class="col-12">
        <b style="color: red;">{{invoice?.UserSettings?.AdminInfo}}</b><br />
      </div>
      
      <!--Invoice info-->
      <div class="col-sm-12 col-md-6" style="height: 615px; margin-bottom: 20px;">

        <mat-card>
          <mat-card-title>
            Fakturainfo
            <span *ngIf="invoice.SubInvoice" class="person" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är en underfaktura"><mat-icon>person</mat-icon></span>
            <span *ngIf="invoice.ParentInvoice" class="person" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är en moderfaktura"><mat-icon>groups</mat-icon></span>
          </mat-card-title>

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.UserSettings.FirstName" matInput placeholder="Förnamn" disabled />
          </mat-form-field>
          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.UserSettings.LastName" matInput placeholder="Efternamn" disabled />
          </mat-form-field>
          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.UserSettings.EmploymentNumber" matInput placeholder="Anställningsnummer" disabled />
          </mat-form-field>
          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.UserSettings.MemberNumber" matInput placeholder="Medlemsnummer" disabled />
          </mat-form-field>

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.InvoiceNumber" matInput placeholder="Fakturanummer" disabled />
          </mat-form-field>

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.Id" matInput placeholder="Faktura-id" disabled />
          </mat-form-field>

          <mat-form-field class="col-12">
            <input type="text" [(ngModel)]="invoice.Ocr" matInput placeholder="OCR" disabled />
          </mat-form-field>

          <mat-form-field class="col-5">
            <input matInput [matDatepicker]="pickerInPayment" [(ngModel)]="invoice.PaymentDate" (dateChange)="invoice.PaymentDate = toFormattedDate($event.value)" placeholder="Inbetalningsdatum">
            <mat-datepicker-toggle matSuffix [for]="pickerInPayment"></mat-datepicker-toggle>
            <mat-datepicker #pickerInPayment></mat-datepicker>
          </mat-form-field>

          <!-- PaidTotal is pre-set to the total invoice amount inc vat, which is set when invoice is sent -->
          <mat-form-field class="col-5">
            <input type="number" maxlength="10" [ngModel]="invoice.PaidTotal" (ngModelChange)="invoice.PaidTotal=$event; updateAmounts(); updateExchangeRatePaid();" matInput placeholder="Totalt inbetalt från kund" />
          </mat-form-field>

          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.TotalInvoiceAmountIncVat" (ngModelChange)="invoice.TotalInvoiceAmountIncVat=$event" name="TotalInvoiceAmountIncVat" matInput placeholder="Totalt fakturerat inkl. moms" disabled />
          </mat-form-field>
          <span class="col-2">
            {{invoice.Currency.Name}}
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.TotalVatAmount" matInput placeholder="Totalt fakturerad moms" disabled />
          </mat-form-field>
          <span class="col-2">
            {{invoice.Currency.Name}}
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="InvoiceFeeReceiver" matInput placeholder="Fakturaavgift" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.TotalInvoiceAmount" matInput placeholder="Totalt fakturerat exkl. moms" disabled />
          </mat-form-field>
          <span class="col-2">
            {{invoice.Currency.Name}}
          </span>

        </mat-card>

      </div>

      <!--Fees added to invoice-->
      <div class="col-sm-12 col-md-6" style="height: 615px; margin-bottom: 20px;">

        <mat-card>
          <mat-card-title>Avgifter som dras från fakturerat belopp</mat-card-title>

          <mat-form-field class="col-10">
            <mat-icon *ngIf="!invoice.InvoiceFeeUnlocked && !invoice.FeePaidByReciever" (click)="invoice.InvoiceFeeUnlocked = !invoice.InvoiceFeeUnlocked" after matSuffix style="cursor: pointer;">lock</mat-icon>
            <mat-icon *ngIf="invoice.InvoiceFeeUnlocked  && !invoice.FeePaidByReciever" (click)="invoice.InvoiceFeeUnlocked = !invoice.InvoiceFeeUnlocked" after color="accent" matSuffix style="cursor: pointer;">lock_open</mat-icon>
            <input type="number" [disabled]="!invoice.InvoiceFeeUnlocked" [ngModel]="InvoiceFeeMember | number:'1.0-0'" (change)="updateAmounts()" (ngModelChange)="invoice.InvoiceFee=$event; InvoiceFeeMember=$event;" matInput placeholder="Fa.avgift medlem" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <mat-icon *ngIf="!invoice.ServiceFeeUnlocked" (click)="invoice.ServiceFeeUnlocked = !invoice.ServiceFeeUnlocked" after matSuffix style="cursor: pointer;">lock</mat-icon>
            <mat-icon *ngIf="invoice.ServiceFeeUnlocked" (click)="invoice.ServiceFeeUnlocked = !invoice.ServiceFeeUnlocked" after color="accent" matSuffix style="cursor: pointer;">lock_open</mat-icon>
            <input type="number" [disabled]="!invoice.ServiceFeeUnlocked" [ngModel]="invoice.ServiceFee" (ngModelChange)="invoice.ServiceFee=$event" matInput placeholder="SAMpoolen-avgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <div class="col-10" style="margin: -15px 0 15px 0; font-size: 80%;">{{ServiceFeeInfo}} </div>
          <mat-form-field class="col-10">
            <mat-icon *ngIf="!invoice.ExchangeFeeUnlocked" (click)="invoice.ExchangeFeeUnlocked = !invoice.ExchangeFeeUnlocked" after matSuffix style="cursor: pointer;">lock</mat-icon>
            <mat-icon *ngIf="invoice.ExchangeFeeUnlocked" (click)="invoice.ExchangeFeeUnlocked = !invoice.ExchangeFeeUnlocked" after color="accent" matSuffix style="cursor: pointer;">lock_open</mat-icon>
            <input type="number" [disabled]="!invoice.ExchangeFeeUnlocked" [ngModel]="invoice.ExchangeFee | number:'1.0-0'" (ngModelChange)="invoice.ExchangeFee=$event" matInput placeholder="Bankavgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.ReminderFee | number:'1.0-0'" (ngModelChange)="invoice.ReminderFee=$event" matInput placeholder="Påminnelseavgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.InkassoFee" (ngModelChange)="invoice.InkassoFee=$event" matInput placeholder="Inkassoavgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.KronofogdenFee" (ngModelChange)="invoice.KronofogdenFee=$event" matInput placeholder="Avgift Kronofogden" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.CustomFee" (ngModelChange)="invoice.CustomFee=$event" matInput placeholder="Manuell avgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="invoice.ManualDiscount" matInput placeholder="Manuell rabatt eller gåva" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
        </mat-card>
      </div>
      <div class="col-lg-6 col-sm-12">
        <mat-card class="grey padding30">
          <mat-checkbox matInput [ngModel]="InvoiceFeePaidByCustomer" (change)="changeValue($event)">Kunden har betalat fakturaavgiften</mat-checkbox>
        </mat-card>
        <mat-card>
          <mat-form-field class="col-10">
            <input type="text" [(ngModel)]="invoice.FeeDescription" matInput placeholder="Fritext / kommentar" />
          </mat-form-field>
        </mat-card>
      </div>
      <!--Partial total amount-->
      <div class="col-lg-6 col-sm-12">
        <mat-card>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.FeesTotalAmount" matInput placeholder="Summa avgifter (inkl kunds ev fakturaavgift)" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.ManualDiscount" matInput placeholder="Manuell rabatt" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [value]="PartialTotalAmount" [(ngModel)]="PartialTotalAmount" matInput placeholder="Delsumma (lönegrundande belopp + nettoersättningar)" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
        </mat-card>
      </div>
      <!-- If a payment date is set, a transaction log is created, otherwise it is just saved -->
      <div class="col-lg-12 col-sm-12" style="margin-top: 10px; padding-right: 25px; float: left;">
        <button mat-flat-button color="accent" (click)="save(invoice)" [disabled]="isLoading" style="float:right; display: block;">Spara</button>
        <a href="#/transactions" class="link">Gå till Ekonomi</a>
      </div>
    </div>
    <div *ngIf="!isLoading && !invoice.ParentInvoice" class="row align-items-end" style="background-color: #f3f3f3; margin-top: 20px; padding-top: 20px; padding-bottom: 30px;">
      <div class="col-12">
        <h2>Beräkning av utbetalning av lön (inkl nettoersättningar)</h2>
      </div>
      <!--Deductions-->
      <div class="col-sm-12 col-md-6">
        <mat-card style="min-height: 565px;">
          <mat-card-title>Nettoersättningar</mat-card-title>
          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="DeductionsTotalAmount" matInput placeholder="Privata avdrag" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="allowancesTotalAmount" matInput placeholder="Traktamenten" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="milageTotalAmount" matInput placeholder="Milersättning" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="invoice.ExpensesTotalAmount" matInput placeholder="Summa nettoersättningar" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="healthCareTotalAmountThisInvoice" matInput placeholder="Varav friskvårdsavdrag" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <div class="col-10 infotext" style="margin-bottom: 10px;">
            <span *ngIf="healthCareAmountExceedesLimit" style="color: red;">Avdragen överstiger 5000 SEK. </span>
            <span *ngIf="healthCareTotalAmountThisInvoice > 0">{{healthCareDeductionsMessage}}</span>
          </div>

          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="PensionSavingsInclTax" matInput placeholder="Tjänstepension inkl skatt" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [(value)]="PartialAmountExclNetExpenses" matInput placeholder="Lönegrundande belopp (delsumma - nettoersättningar - tjänstepension inkl skatt)" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
        </mat-card>
      </div>
      <!--Taxes-->
      <div class="col-sm-12 col-md-6">
        <mat-card style="min-height: 565px;">
          <mat-card-title>Skatter</mat-card-title>
          <mat-form-field class="col-10">
            <input type="number" [(value)]="PartialAmountExclNetExpenses" matInput placeholder="Lönegrundande belopp (delsumma - nettoersättningar)" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-4">
            <input type="number" [value]="invoice.ArbetsgivarAvgift" matInput placeholder="Arbetsgivaravgift" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <span class="col-6">
            <mat-form-field>
              <mat-select required [(ngModel)]="invoice.EmployerTaxType.Id" name="employertax" id="employertax" placeholder="Arb.g.avg" (ngModelChange)="invoice.EmployerTaxType.Id = $event; employerTaxUpdated($event);">
                <mat-option *ngFor="let item of employerTaxes" [value]="item.Id">
                  {{ item.Tax * 100 | number: '1.0-2' }} % ({{item.Name}})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.BruttoLon" matInput placeholder="Bruttolön (lönegrundande belopp - arb.g.avgift)" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-8">
            <input type="number" [(ngModel)]="PayoutMunicipalityTax" matInput placeholder="Kommunalskatt" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <span class="col-2" *ngIf="invoice?.UserSettings?.TaxAdjustments == null || invoice?.UserSettings?.TaxAdjustments.length == 0">
            {{ invoice?.UserSettings?.Municipality?.Tax * 100  | number: '1.2-2'}} %
          </span>

          <span class="col-2" *ngIf="invoice?.UserSettings?.TaxAdjustments != null && invoice?.UserSettings?.TaxAdjustments.length > 0">
            {{ invoice?.UserSettings?.TaxAdjustments[0]?.TemporaryTax * 100  | number: '1.2-2'}} %
          </span>

          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="invoice.NettoLon" matInput placeholder="Nettolön (bruttolön - kommunalskatt)" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <div *ngIf="invoice?.UserSettings?.HasCoordinationNumber" style="color: red; font-size: 80%; padding: 0 0 0 15px; font-style: italic;">
            Medlemmen har samordningsnummer. Angivet födelseår: {{ invoice?.UserSettings?.BirthYear }}
          </div>

        </mat-card>

      </div>

      <!--Payment / net salary-->
      <div class="col-sm-12 col-md-6" style="min-height: 385px;">

        <mat-card>
          <mat-card-title>Utbetalning</mat-card-title>

          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="invoice.NettoLon" matInput placeholder="Nettolön" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="invoice.ExpensesTotalAmount" matInput placeholder="Summa nettoersättningar" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
                    
          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="PensionSavingsInclTax" matInput placeholder="Tjänstepension inkl skatt" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="invoice.PayOutTotal" matInput placeholder="Beräkning utbetalning" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
          <div class="infotext" style="color: red;" *ngIf="invoice.ExpensesTotalAmount > PartialTotalAmount"><i>Nettoersättningarna överstiger delbeloppet</i></div>

          <mat-form-field class="col-10" *ngIf="invoice.ExchangeRateInvoiceSent != 0">
            <input type="number" [(ngModel)]="invoice.ExchangeRateInvoicePaid" (ngModelChange)="invoice.invoice.ExchangeRateInvoicePaid=$event;" matInput placeholder="Valutakurs vid utbetalning" />
          </mat-form-field>
          <span class="col-2" *ngIf="invoice.ExchangeRateInvoiceSent != 0">
            SEK
          </span>

        </mat-card>

      </div>

      <!--Payment / net salary / save -->
      <div class="col-sm-12 col-md-6" style="min-height: 385px;">
        <mat-card>
          <div class="row col-lg-12" style="margin: 0 0 20px -15px">
            <!--<div class="row col-lg-12"><div class="col-lg-6">Totalt att betala ut : </div><div class="col-lg-6"><b>{{ invoice.PayOutTotal }}</b></div></div>-->
            <!--<div class="row col-lg-12" ><div class="col-lg-6">Utmätning till Kfm: </div><div class="col-lg-6">{{ attachmentPaymentToKronofogdenAmount }}</div></div>-->
            <div class="row col-lg-12"><br /><br /><div class="col-lg-6">Personnr: </div><div class="col-lg-6">{{ invoice?.UserSettings?.PersonalNumber }}</div></div>

            <div class="row col-lg-12" *ngIf="invoice.PensionSavingsAmount > 0"><div class="col-lg-6">Totalt till tjänstepension: </div><div class="col-lg-6"><b>{{ invoice.PensionSavingsAmount + pensionSavingsTaxAmount}}</b> ({{ invoice.PensionSavingsAmount + '+' + pensionSavingsTaxAmount}})</div></div>
            <div class="row col-lg-12" *ngIf="invoice.PensionSavingsAmount > 0"><div class="col-lg-6">Tjänstepensionsföretag: </div><div class="col-lg-6">{{ invoice?.UserSettings?.PensionSavingsSettings?.PensionSavingsCompany?.Name}}</div></div>

            <div *ngIf="!hasPreviousPensionSaving && invoice.PensionSavingsAmount > 0" style="width: 100%; border: 1px solid grey;">
              <div class="row col-lg-12"><div class="col-lg-6">Epost: </div><div class="col-lg-6">{{ invoice?.UserSettings?.Email}}</div></div>
              <div class="row col-lg-12"><div class="col-lg-6">Telefon: </div><div class="col-lg-6">{{ invoice?.UserSettings?.Phone}}</div></div>
            </div>
            <div *ngIf="hasPreviousPensionSaving && invoice.PensionSavingsAmount > 0" class="row col-lg-12" style="width: 100%; height: 20px">
              <div class="col-lg-12" style="font-style: italic;">{{pensionSavingsMessage}}</div>
            </div>

            <br /><br />
            <div class="row col-lg-12"><div class="col-lg-6">Bank: </div><div class="col-lg-6">{{ invoice?.UserSettings?.BankName }}</div></div>
            <div class="row col-lg-12"><div class="col-lg-6" *ngIf="invoice?.UserSettings?.ClearingNumber != '3300'">Clearing + kontonummer: </div><div class="col-lg-6" *ngIf="invoice?.UserSettings?.ClearingNumber != '3300'">{{ invoice?.UserSettings?.ClearingNumber }} {{ invoice?.UserSettings?.AccountNumber }}</div></div>
            <div class="row col-lg-12"><div class="col-lg-6" *ngIf="invoice?.UserSettings?.ClearingNumber == '3300'">Personkonto: </div><div class="col-lg-6" *ngIf="invoice?.UserSettings?.ClearingNumber == '3300'">{{ invoice?.UserSettings?.AccountNumber }}</div></div>
            <div class="row col-lg-12"><div class="col-lg-6">Totalt att betala ut : </div><div class="col-lg-6"><b>{{ invoice.PayOutTotal }}</b></div></div>
            <div class="row col-lg-12"><div class="col-lg-6">Text avsändarkonto: </div><div class="col-lg-6">{{ invoice?.UserSettings?.EmploymentNumber }} <span *ngIf="!invoice.SubInvoice">{{ invoice.InvoiceNumber }}</span> <span *ngIf="invoice.SubInvoice">{{ getInvoiceNumberPart(invoice.InvoiceNumber) }}</span> <span *ngIf="invoice.ApprovedForExpress && invoice.ExpressPayOut">x</span></div></div>
            <div class="row col-lg-12"><div class="col-lg-6">Text mottagarkonto: </div><div class="col-lg-6">sam{{invoice?.InvoiceNumber}}</div></div>
          </div>

          <!--<div *ngIf="invoice?.UserSettings?.AttachmentOfSalaries.length != 0 && invoice?.UserSettings?.AttachmentOfSalaries.length > 0" class="row col-lg-12" style="margin: 0 0 20px 0; font-size: 92%; border: 1px solid #efefef;">
          <h4>Utmätning</h4>
          <div class="row col-lg-12"><div class="col-lg-9">Startdatum: </div><div class="col-lg-3">{{ invoice?.UserSettings?.AttachmentOfSalaries[0]?.DateStart | date: 'yyyy-MM-dd' }}</div></div>
          <div class="row col-lg-12"><div class="col-lg-9">Slutdatum: </div><div class="col-lg-3">{{ invoice?.UserSettings?.AttachmentOfSalaries[0]?.DateEnd | date: 'yyyy-MM-dd' }}</div></div>
          <div class="row col-lg-12"><div class="col-lg-9">Reserverat belopp: </div><div class="col-lg-3">{{ invoice?.UserSettings?.AttachmentOfSalaries[0]?.ReservedAmount }}</div></div>
          <div class="row col-lg-12"><div class="col-lg-9">Utmätningsbelopp: </div><div class="col-lg-3">{{ invoice?.UserSettings?.AttachmentOfSalaries[0]?.AttachmentAmount }}</div></div>
          <div class="row col-lg-12"><div class="col-lg-9">Utbetalat till Kfm denna månad: </div><div class="col-lg-3">{{ invoice?.UserSettings?.AttachmentOfSalaries[0]?.TotalAttachmentPayoutThisMonth }}</div></div>
          <div class="row col-lg-12"><div class="col-lg-9">Total lön utbetald denna månad: </div><div class="col-lg-3">{{ invoice?.UserSettings?.AttachmentOfSalaries[0]?.TotalSalaryPayoutThisMonth }}</div></div>
        </div>-->


          <mat-form-field class="col-10">
            <input matInput [matDatepicker]="pickerPayment" [(ngModel)]="invoice.PayoutDate" (dateChange)="invoice.PayoutDate = toFormattedDate($event.value)" placeholder="Datum för utbetalning">
            <mat-datepicker-toggle matSuffix [for]="pickerPayment"></mat-datepicker-toggle>
            <mat-datepicker #pickerPayment></mat-datepicker>
          </mat-form-field>
          <div [style.color]="(taxChanged) ? 'red' : 'black'" style="font-size: 80%; padding-left: 15px; font-style: italic;">
            <div [innerHTML]="lastPayoutMessage"></div>
          </div>
          <div *ngIf="invoice.SubInvoice && invoice.ExpressPayOut && !invoice.ApprovedForExpress" style="color: red; font-size: 80%; padding-left: 15px; font-style: italic;">
            Expressutbetalning är vald, men moderfakturan är ej bekräftad
          </div>
          <div *ngIf="!invoice.SubInvoice && !invoice.ParentInvoice && invoice.ExpressPayOut && !invoice.ApprovedForExpress" style="color: red; font-size: 80%; padding-left: 15px; font-style: italic;">
            Expressutbetalning är vald, men fakturan är ej bekräftad
          </div>

        </mat-card>

        <div class="col-lg-12 col-sm-12" style="margin-top: 10px; float: left;">
          <button mat-flat-button color="accent" (click)="save(invoice,true)" [disabled]="isLoading || !saveValid" style="float:right;">Spara <span matTooltipPosition="after" area-label="Hover to display info" matTooltip="Sparar underlaget, skapar utbetalningslogg om datum är nytt eller ändrat. Skickar då mail till medlem om utbetalning."><mat-icon class="infoicon">info</mat-icon></span></button>
          <button color="accent" mat-stroked-button (click)="save(invoice,false)" [disabled]="isLoading || !saveValid" style="float:left;">Spara utan att skicka epost <span matTooltipPosition="after" area-label="Hover to display info" matTooltip="Sparar hela underlaget och skapar alltid ny utbetalningslogg utan att skicka mail till medlem."><mat-icon class="infoicon">info</mat-icon></span></button>
          <a href="#/transactions" class="link">Gå till Ekonomi</a>
        </div>
        <div class="col-lg-12 col-sm-12" style="margin-top: 10px; float: right; font-size: 80%; color: red;">
          {{saveMessage}}
        </div>
      </div>
    </div>

    <table *ngIf="!isLoading" [class.light-grey]="!saveValid" style="margin-top: 20px; width: 100%;">
      <thead>
        <tr>
          <th>Löneart</th>
          <th>Km / antal</th>
          <th>SEK/dag</th>
          <th>Arb.giv.avg.</th>
          <th>Bruttolön</th>
          <th>Ink.skatt</th>
          <th style="text-align: right;">Nettolön/belopp</th>
        </tr>
      </thead>
      <tbody>
        <!-- Invoice-level information -->
        <tr>
          <td>1101</td>
          <td></td>
          <td></td>
          <td>{{ invoice?.ArbetsgivarAvgift }}</td>
          <td>{{ invoice?.BruttoLon }}</td>
          <td>{{ PayoutMunicipalityTax }}</td>
          <td style="text-align: right;">{{ invoice?.NettoLon }}</td>
        </tr>
        <!-- Deductions -->
        <tr *ngFor="let deduction of approvedDeductions">
          <td>{{ deduction?.TravelExpense ? '1223' : deduction?.HealthCare ? '1228' : '1224' }}{{ deduction?.AddedToInvoice ? '1' : '' }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="text-align: right;">{{ deduction?.Amount }}</td>
        </tr>
        <!-- Allowances -->
        <ng-container *ngIf="invoice?.Allowances">
          <ng-container *ngFor="let allowance of invoice.Allowances">
            <tr *ngIf="allowance.Mileage > 0">
              <td>1232</td>
              <td>{{ allowance?.Mileage }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="text-align: right;">{{ allowance?.Sum }}</td>
            </tr>
            <tr *ngIf="allowance.FullDays > 0">
              <td>{{ allowance?.AllowanceType.Id == 2 ? '1201' : '1204' }}</td>
              <td>{{ allowance?.FullDays }}</td>
              <td>{{ allowance?.AllowancePerDay }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td style="text-align: right;">{{ allowance?.sumAllowanceFullDays }}</td>
            </tr>
            <tr *ngIf="allowance.HalfDays > 0">
              <td>{{ allowance?.AllowanceType.Id == 2 ? '1202' : '1204 (halv)' }}</td>
              <td>{{ allowance?.HalfDays }}</td>
              <td>{{ allowance?.AllowancePerDay / 2 }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td style="text-align: right;">{{ allowance?.sumAllowanceHalfDays }}</td>
            </tr>
            <tr *ngIf="allowance.Brekafasts > 0">
              <td>{{ allowance?.AllowanceType.Id == 2 ? '1209' : '1215' }}</td>
              <td>{{ allowance?.Brekafasts }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="text-align: right;">{{ allowance?.BrekafastsAmount }}</td>
            </tr>
            <tr *ngIf="(allowance.Lunches > 0 || allowance.Dinners > 0) && allowance.AllowanceType.Id == 2">
              <td>1210</td>
              <td>{{ allowance?.Lunches + allowance?.Dinners }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="text-align: right;">{{ allowance?.LunchesAmount + allowance?.DinnersAmount }}</td>
            </tr>
            <tr *ngIf="allowance.Lunches > 0 && allowance.AllowanceType.Id == 3">
              <td>1216</td>
              <td>{{ allowance?.Lunches }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="text-align: right;">{{ allowance?.LunchesAmount }}</td>
            </tr>
            <tr *ngIf="allowance.Dinners > 0 && allowance.AllowanceType.Id == 3">
              <td>1217</td>
              <td>{{ allowance?.Dinners }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style="text-align: right;">{{ allowance?.DinnersAmount }}</td>
            </tr>
          </ng-container>
        </ng-container>
        <tr *ngIf="invoice.PensionSavingsAmount > 0">
          <td>{{invoice?.UserSettings?.PensionSavingsSettings?.PensionSavingsCompany?.Name}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="text-align: right;">{{ invoice.PensionSavingsAmount + '+' + pensionSavingsTaxAmount}}</td>
        </tr>
        <tr>
          <td>1313</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style="text-align: right;">{{ invoice.PayOutTotal }}</td>
        </tr>
      </tbody>
    </table>

  </div>  
</div>
