var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
//import { Http, HttpModule, Headers, Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
//import { BrowserModule } from '@angular/platform-browser';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { MatDialog } from '@angular/material';
import { ActionAuthLogin, ActionAuthLogout } from '@app/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/ConfirmDialog';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(dialog, http, authGuardService, localStorageService, 
    //private organizationService: OrganizationService,
    store, router, snackBar) {
        this.dialog = dialog;
        this.http = http;
        this.localStorageService = localStorageService;
        this.store = store;
        this.router = router;
        this.snackBar = snackBar;
        this.accountCompletionSettings = { orgNumber: null, email: null, response: null };
        this.userDataIsUpdated = false;
        this.subject = new Subject();
        this.config = {
            disableClose: true,
            panelClass: 'full-screen-modal',
            hasBackdrop: true,
            backdropClass: '',
            width: '500px',
            maxWidth: '100vw',
            height: '100%',
            position: {
                top: '',
                bottom: '',
                left: '',
                right: ''
            },
            data: {
                invoiceItemGroup: {},
                customerTemplate: {},
                contact: { IDNumber: null },
                companyId: 0,
                status: {},
                customerCountryName: null,
            },
            dataTemplate: {
                Id: 0,
                Country: { Id: 1 },
                Type: {},
                HoursWorked: undefined,
                StartDate: undefined,
                EndDate: undefined,
                Comment: undefined,
                InvoiceId: 0
            }
        };
        this.authGuardService = authGuardService;
        this.setHttpOptions();
        var userData = JSON.parse(localStorage.getItem('ANMS-AUTH'));
        if (userData != undefined && userData.token != undefined) {
            this.token = userData.token.access_token;
            this.userData = userData;
            this.checkForAccountCompletion();
        }
        this.apiBaseUrl = env.hostApi;
        this.apiAuthUrl = env.hostAuth;
        this.appId = env.appId;
    }
    AuthenticationService.prototype.ngOnInit = function () {
    };
    AuthenticationService.prototype.userUpdated = function (message) {
        this.subject.next({ text: message });
    };
    AuthenticationService.prototype.getUserData = function () {
        return this.subject.asObservable();
    };
    AuthenticationService.prototype.login = function (username, password, bankIdpersonalNumber, emailEncrypted, userId, pid, appId) {
        var _this = this;
        if (username === void 0) { username = null; }
        if (password === void 0) { password = null; }
        if (bankIdpersonalNumber === void 0) { bankIdpersonalNumber = null; }
        if (emailEncrypted === void 0) { emailEncrypted = null; }
        if (userId === void 0) { userId = null; }
        if (pid === void 0) { pid = null; }
        if (appId === void 0) { appId = null; }
        var data = "grant_type=password&username=" + username + "&password=" + password;
        var url = this.apiAuthUrl;
        if (bankIdpersonalNumber != null)
            url = url + "?bankIdpersonalNumber=" + bankIdpersonalNumber;
        if (bankIdpersonalNumber != null)
            url = url + "&emailEncrypted=" + emailEncrypted;
        if (pid != null)
            url = url + "&pid=" + pid;
        if (appId != null)
            url = url + "&appid=" + appId;
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            })
        };
        return this.http.post(url, data, httpOptions)
            .pipe(tap(function (response) {
            return _this.saveToken(response, username);
        }), catchError(this.handleError('login', data)));
    };
    AuthenticationService.prototype.loginBankIdPassive = function (personalNumber, userId) {
        var _this = this;
        if (userId === void 0) { userId = null; }
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        var url = this.apiBaseUrl + "BankId/" + personalNumber + "/AuthenticatePassive";
        if (userId != null)
            url = url + "&userId=" + userId;
        return this.http.get(url, httpOptions)
            .pipe(tap(function (response) {
            return _this.saveToken(response, response.userName);
        }), catchError(this.handleError('bankid_passive')));
    };
    AuthenticationService.prototype.onUserLoggedIn = function (response) {
        this.saveToken(response, "");
    };
    AuthenticationService.prototype.createAccount = function (user) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        var data = JSON.stringify(user);
        return this.http.post(this.apiBaseUrl + 'Users', data, httpOptions)
            .pipe(tap(function (response) {
            return console.log(response);
        }), catchError(this.handleError('login', "error")));
    };
    AuthenticationService.prototype.handleError = function (operation, result) {
        var _this = this;
        if (operation === void 0) { operation = 'operation'; }
        return function (error) {
            var config = new MatSnackBarConfig();
            config.duration = 15000;
            console.log('Err:');
            console.log(error);
            if (error.status == 400) {
                _this.snackBar.open("Fel användarnamn eller lösenord! \r\n\r\nOm du skapat ett konto via epost, logga in på det och ange personnummer för att koppla till BankID!", undefined, config);
            }
            else if (error.status == 409) {
                _this.snackBar.open("Epostadressen är upptagen!", undefined, config);
            }
            else if (error.statusText == 'Unknown Error') {
                _this.snackBar.open("Något gick fel. Försök gärna logga in igen, och kontakta support om problemet kvarstår.", undefined, config);
            }
            else if (error.status == 500) {
                _this.snackBar.open(error.error.ExceptionMessage, undefined, config);
            }
            else {
                _this.snackBar.open(error.error, undefined, config);
            }
            return of(result);
        };
    };
    AuthenticationService.prototype.getCurrentUser = function () {
        var _this = this;
        var url = this.apiBaseUrl + 'User/My';
        var bearerToken = 'bearer ' + this.token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
        return this.http.get(url, this.httpOptions)
            .pipe(tap(function (response) { return _this.onFinished(response); }), catchError(function (err) {
            console.log(err);
            return new Observable();
        }));
    };
    AuthenticationService.prototype.searchOrganizationById = function (id) {
        var _this = this;
        var url = this.apiBaseUrl + 'Organizations/Search/' + id;
        return this.http.get(url, this.httpOptions)
            .pipe(tap(function (response) { return _this.onRetrieved(response); }), catchError(function (err) {
            return new Observable();
        }));
    };
    AuthenticationService.prototype.onRetrieved = function (response) {
        console.log(response);
    };
    AuthenticationService.prototype.checkForAccountCompletion = function () {
        var _this = this;
        setTimeout(function () {
            var url = _this.apiBaseUrl + 'Users/Me/NeedsCompletion';
            _this.http.get(url, _this.httpOptions).subscribe(function (response) {
                _this.handleAccountCompletion(response);
            }, function (error) {
                console.error('Error occurred during HTTP call:', error);
            });
        }, 1500);
    };
    AuthenticationService.prototype.updateCurrentUserData = function (email, organizationNumber, sampoolenIsMainEmployer, appId) {
        var _this = this;
        if (organizationNumber === void 0) { organizationNumber = null; }
        if (sampoolenIsMainEmployer === void 0) { sampoolenIsMainEmployer = null; }
        if (appId === void 0) { appId = "2"; }
        debugger;
        email = email == null ? '' : email;
        organizationNumber = organizationNumber == null ? '' : organizationNumber;
        sampoolenIsMainEmployer = sampoolenIsMainEmployer == null ? '' : sampoolenIsMainEmployer;
        var url = this.apiBaseUrl + 'Users/Me';
        var bearerToken = 'bearer ' + this.token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
        var sampoolenIsMainEmployerBool = sampoolenIsMainEmployer.toLowerCase() == "ja" ? true : false;
        var payload = JSON.parse('{"SystemAppId": "' + appId + '", "Email": "' + email + '", "OrganizationNr": "' + organizationNumber + '", "SampoolenIsMainEmployer": "' + sampoolenIsMainEmployer + '"}');
        this.http.patch(url, payload, this.httpOptions).subscribe(function (response) {
            debugger;
            _this.userDataUpdated(response);
        }, function (errorResponse) {
            debugger;
            _this.handleAccountCompletion(null, errorResponse.error.Message);
        });
    };
    AuthenticationService.prototype.handleAccountCompletion = function (response, errorMessage) {
        if (errorMessage === void 0) { errorMessage = null; }
        return __awaiter(this, void 0, void 0, function () {
            var errorType, _a, _b, _c, emailValidates, organizationValidates, error_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 8, , 9]);
                        // Set the completioninfo for later use if function is calles again
                        if (response != null)
                            this.accountCompletionSettings.response = response;
                        errorType = errorMessage != null && errorMessage.includes("Epostadressen") ? "email" : null;
                        if (!this.accountCompletionSettings.response.NeedsCompletion) return [3 /*break*/, 7];
                        if (!((this.accountCompletionSettings.response.CompleteEmail && this.accountCompletionSettings.userEmail == null) || errorType == "email")) return [3 /*break*/, 2];
                        _a = this.accountCompletionSettings;
                        return [4 /*yield*/, this.openGetUserEmailModal(errorMessage)];
                    case 1:
                        _a.userEmail = _d.sent();
                        _d.label = 2;
                    case 2:
                        if (!(this.appId == 2 && (this.accountCompletionSettings.response.CompleteOrganization && this.accountCompletionSettings.orgNumber == null) || errorType == "orgNumber")) return [3 /*break*/, 4];
                        _b = this.accountCompletionSettings;
                        return [4 /*yield*/, this.openGetOrganizationnumberModal()];
                    case 3:
                        _b.orgNumber = _d.sent();
                        _d.label = 4;
                    case 4:
                        if (!(this.appId == 1 && ((this.accountCompletionSettings.response.CompleteSampoolenIsMainEmployer && this.accountCompletionSettings.sampoolenIsMainEmployer == null) || errorType == "employer"))) return [3 /*break*/, 6];
                        _c = this.accountCompletionSettings;
                        return [4 /*yield*/, this.openGetEmployerModal()];
                    case 5:
                        _c.sampoolenIsMainEmployer = _d.sent();
                        _d.label = 6;
                    case 6:
                        // Sampoolen
                        if (this.appId == 1) {
                            if (this.accountCompletionSettings.sampoolenIsMainEmployer != null) {
                                this.updateCurrentUserData(null, null, this.accountCompletionSettings.sampoolenIsMainEmployer, this.appId.toString());
                            }
                        }
                        // Samfinans
                        else if (this.appId == 2) {
                            emailValidates = !this.accountCompletionSettings.response.CompleteEmail || (this.accountCompletionSettings.response.CompleteEmail && this.accountCompletionSettings.userEmail != null);
                            organizationValidates = !this.accountCompletionSettings.response.CompleteOrganization || (this.accountCompletionSettings.response.CompleteOrganization && this.accountCompletionSettings.orgNumber != null);
                            //Only update if email or organization is set
                            if (this.accountCompletionSettings.userEmail != null || this.accountCompletionSettings.orgNumber != null) {
                                if (emailValidates && organizationValidates) {
                                    this.updateCurrentUserData(this.accountCompletionSettings.userEmail, this.accountCompletionSettings.orgNumber, this.accountCompletionSettings.sampoolenIsMainEmployer, this.appId.toString());
                                }
                            }
                        }
                        _d.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        error_1 = _d.sent();
                        // Handle any error that occurred during the modal operations
                        alert('An error occurred during account completion.');
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.openGetUserEmailModal = function (errorMessage) {
        if (errorMessage === void 0) { errorMessage = null; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        // Trigger modal to fill email address
                        var config = JSON.parse(JSON.stringify(_this.config));
                        config.height = 310;
                        config.width = 400;
                        config.data.hideInput = false;
                        config.data.requireInput = false;
                        config.data.hideCancelButton = true;
                        config.data.inputTitle = 'Ange din epostadress';
                        config.data.confirmButtonText = "Spara!";
                        config.data.confirmButtonIcon = "check";
                        config.data.errorText = errorMessage;
                        if (_this.appId == 1) {
                            config.data.headline = "Hej! Välkommen till SAMpoolen";
                            config.data.text = "Ange din epostadress så kan vi starta igång sedan.";
                        }
                        if (_this.appId == 2) {
                            config.data.headline = "Hej! Välkommen till SAMekonomi";
                            config.data.text = "Vi ser att det är första gången du besöker oss. Låt oss börja med att skapa ditt personkonto:";
                        }
                        var dialogRef = _this.dialog.open(ConfirmDialogComponent, config);
                        dialogRef.afterClosed().subscribe(function (result) {
                            dialogRef = null;
                            if (result.Confirmed) {
                                if (!_this.validateEmail(result.ResponseText)) {
                                    reject("Ange en giltig epostadress!");
                                }
                                else {
                                    resolve(result.ResponseText);
                                }
                            }
                            else {
                                reject("Vänligen ange en annan epostadress!");
                            }
                        });
                    })];
            });
        });
    };
    AuthenticationService.prototype.openGetOrganizationnumberModal = function (errorMessage) {
        var _this = this;
        if (errorMessage === void 0) { errorMessage = null; }
        return new Promise(function (resolve, reject) {
            var config = JSON.parse(JSON.stringify(_this.config));
            config.height = 310;
            config.width = 400;
            config.data.hideInput = false;
            config.data.requireInput = false;
            config.data.hideCancelButton = true;
            config.data.inputTitle = 'Organisationsnummer';
            config.data.headline = "Organisation";
            config.data.confirmButtonText = "Spara!";
            config.data.confirmButtonIcon = "check";
            config.data.text = "Tack. Då är ditt personkonto klart. Vilket bolag vill du koppla? Ange org.nr så ser vi om vi hittar det.";
            config.data.errorText = errorMessage;
            var dialogRef = _this.dialog.open(ConfirmDialogComponent, config);
            dialogRef.afterClosed().subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
                var companySearchResponse, confirmOrganization, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dialogRef = null;
                            if (!result.Confirmed) return [3 /*break*/, 8];
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 6, , 7]);
                            return [4 /*yield*/, this.searchOrganizationById(result.ResponseText).toPromise()];
                        case 2:
                            companySearchResponse = _a.sent();
                            if (!companySearchResponse.IsRegisteredInOrganization) return [3 /*break*/, 3];
                            this.openGetOrganizationnumberModal('Företaget redan registrerat!').then(resolve).catch(reject);
                            return [3 /*break*/, 5];
                        case 3: return [4 /*yield*/, this.openConfirmOrganizationModal(companySearchResponse.Name)];
                        case 4:
                            confirmOrganization = _a.sent();
                            if (confirmOrganization) {
                                resolve(result.ResponseText);
                            }
                            else {
                                this.openGetOrganizationnumberModal().then(resolve).catch(reject);
                            }
                            _a.label = 5;
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            error_2 = _a.sent();
                            console.error(error_2);
                            reject('Organization number search failed');
                            return [3 /*break*/, 7];
                        case 7: return [3 /*break*/, 9];
                        case 8:
                            reject('Organization number input was canceled');
                            _a.label = 9;
                        case 9: return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    AuthenticationService.prototype.openGetEmployerModal = function (errorMessage) {
        var _this = this;
        if (errorMessage === void 0) { errorMessage = null; }
        return new Promise(function (resolve, reject) {
            var config = JSON.parse(JSON.stringify(_this.config));
            config.height = 400;
            config.width = 400;
            config.disableClose = false;
            config.data.hideInput = false;
            config.data.requireInput = false;
            config.data.hideCancelButton = true;
            config.data.inputTitle = 'Svarsalternativ: ja/nej';
            config.data.headline = "Arbetsgivare";
            config.data.useYesNo = true;
            config.data.confirmButtonText = "Spara!";
            config.data.confirmButtonIcon = "check";
            config.data.text = "Är Sampoolen din huvudsakliga arbetsgivare? <br><br>Huvudarbetsgivare är den arbetsgivare som du din huvudsakliga inkomst från. <br><br> Om du får lön från flera arbetsgivare under ett kalenderår, ska du svara ja <strong>endast</strong> om du får mer inkomst från oss än från någon annan arbetsgivare.";
            config.data.errorText = errorMessage;
            var dialogRef = _this.dialog.open(ConfirmDialogComponent, config);
            dialogRef.afterClosed().subscribe(function (result) { return __awaiter(_this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    dialogRef = null;
                    if (result.Confirmed) {
                        try {
                            response = result.ResponseText.toLowerCase();
                            if (response == "ja" || response == "nej") {
                                resolve(response);
                            }
                            else {
                                this.openGetEmployerModal().then(resolve).catch(reject);
                            }
                        }
                        catch (error) {
                            console.error(error);
                            reject('Employer input failed');
                        }
                    }
                    else {
                        reject('Employer input canceled');
                    }
                    return [2 /*return*/];
                });
            }); });
        });
    };
    AuthenticationService.prototype.userDataUpdated = function (response) {
        var _this = this;
        this.userDataIsUpdated = true;
        // Trigger modal to fill email address
        var config = JSON.parse(JSON.stringify(this.config));
        config.height = 325;
        config.width = 400;
        config.data.hideInput = true;
        config.data.requireInput = false;
        config.data.hideCancelButton = true;
        config.data.headline = "Registrering färdig!";
        config.data.confirmButtonText = "Ok";
        config.data.confirmButtonIcon = "check";
        if (this.appId == 1) {
            config.data.text = "Tack för ditt förtroende. Tveka inte höra av dig om det dyker upp några frågor och/eller funderingar!";
        }
        if (this.appId == 2) {
            config.data.text = "Perfekt! Nu är både ditt personkonto samt företagets konto skapat. Tveka inte höra av dig om det dyker upp några frågor och/eller funderingar! Tack för ert förtroende!";
        }
        var dialogRef = this.dialog.open(ConfirmDialogComponent, config);
        dialogRef.afterClosed().subscribe(function (result) {
            dialogRef = null;
            _this.getCurrentUser()
                .subscribe(function (data) {
                var userString = localStorage.getItem('ANMS-AUTH');
                var currentUserObject = JSON.parse(userString);
                currentUserObject.User = data;
                localStorage.setItem('ANMS-AUTH', JSON.stringify(currentUserObject));
                _this.userData = currentUserObject;
            }, function (error) {
                alert('err');
            });
        });
    };
    AuthenticationService.prototype.openConfirmOrganizationModal = function (orgName) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var config = JSON.parse(JSON.stringify(_this.config));
            config.height = 310;
            config.width = 400;
            config.data.hideInput = true;
            config.data.requireInput = false;
            config.data.hideCancelButton = false;
            config.data.inputTitle = 'Bekräfta';
            config.data.headline = "Bekräfta organisation";
            config.data.confirmButtonText = "Ja";
            config.data.confirmButtonIcon = "check";
            config.data.text = "Är " + orgName + " rätt organisation?";
            var dialogRef = _this.dialog.open(ConfirmDialogComponent, config);
            dialogRef.afterClosed().subscribe(function (result) {
                dialogRef = null;
                if (result.Confirmed) {
                    resolve(true);
                }
                else {
                    resolve(false); // Resolve with false indicating the user canceled the confirmation
                }
            });
        });
    };
    AuthenticationService.prototype.validateEmail = function (email) {
        // Regular expression for validating email addresses
        var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Test the email against the regex
        return emailRegex.test(email);
    };
    AuthenticationService.prototype.onFinished = function (response) {
        var userString = localStorage.getItem('ANMS-AUTH');
        var currentUserObject = JSON.parse(userString);
        this.userData = currentUserObject;
        currentUserObject.User = response;
        localStorage.setItem('ANMS-AUTH', JSON.stringify(currentUserObject));
        this.userUpdated("");
        if (!this.userDataIsUpdated)
            this.checkForAccountCompletion();
    };
    AuthenticationService.prototype.setHttpOptions = function () {
        var bearerToken = 'bearer ' + this.token;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
    };
    AuthenticationService.prototype.currentUserHasCoordinationNumber = function () {
        return this.userData.User.AccountSettings.HasCoordinationNumber;
    };
    AuthenticationService.prototype.currentUserUserId = function () {
        return this.userData.User.AccountSettings.Guid;
    };
    AuthenticationService.prototype.getCurrentUserOrganization = function () {
        return this.userData.User.AccountSettings.Organization;
    };
    AuthenticationService.prototype.setCurrentUserOrganizationCompany = function (company) {
        if (company == null)
            return;
        this.userData.User.AccountSettings.Organization.Company = company;
        var storedUserData = JSON.parse(localStorage.getItem('ANMS-AUTH'));
        storedUserData.User.AccountSettings.Organization.Company = company;
        localStorage.setItem('ANMS-AUTH', JSON.stringify(storedUserData));
    };
    AuthenticationService.prototype.currentUserIsLoggedInWithBankId = function () {
        return this.userData.User.LoginType === 'bankid';
    };
    AuthenticationService.prototype.currentUserHasPersonalNumberSet = function () {
        return this.userData.User.AccountSettings.PersonalNumber != null ? true : false;
    };
    AuthenticationService.prototype.saveToken = function (response, username) {
        var _this = this;
        // login successful if there's a jwt token in the response
        if (response) {
            // set token property
            this.token = response.access_token;
            this.store.dispatch(new ActionAuthLogin());
            // store username and jwt token in local storage to keep user logged in between page refreshes
            var userString = JSON.stringify({ isAuthenticated: true, username: username, token: response });
            localStorage.setItem('ANMS-AUTH', userString);
            var currentUserObject = JSON.parse(userString);
            this.token = currentUserObject.token.access_token;
            this.getCurrentUser()
                .subscribe(function (data) {
                var userString = localStorage.getItem('ANMS-AUTH');
                var currentUserObject = JSON.parse(userString);
                currentUserObject.User = data;
                localStorage.setItem('ANMS-AUTH', JSON.stringify(currentUserObject));
                _this.userData = currentUserObject;
            }, function (error) {
                alert('err');
            });
            return true;
        }
        else {
            // return false to indicate failed login
            return false;
        }
    };
    AuthenticationService.prototype.requestResetPassword = function (user, appId) {
        var _this = this;
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        var data = JSON.stringify(user);
        return this.http.post(this.apiBaseUrl + 'Users/ForgotPassword?appId=' + appId, data, httpOptions)
            .pipe(tap(function (response) {
            return _this.requestResetPasswordFinished();
        }), catchError(this.handleError('login', data)));
    };
    AuthenticationService.prototype.requestResetPasswordFinished = function () {
        var config = new MatSnackBarConfig();
        config.duration = 7000;
        this.snackBar.open("E-postmeddelande skickat!", undefined, config);
        this.router.navigateByUrl("/login");
    };
    AuthenticationService.prototype.resetPassword = function (request) {
        var _this = this;
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        var data = JSON.stringify(request);
        return this.http.post(this.apiBaseUrl + 'Users/ResetPassword', data, httpOptions)
            .pipe(tap(function (response) {
            return _this.resetPasswordFinished();
        }), catchError(this.handleError('resetpassword', data)));
    };
    AuthenticationService.prototype.resetPasswordFinished = function () {
        var config = new MatSnackBarConfig();
        config.duration = 7000;
        this.snackBar.open("Lösenordet återställt!", undefined, config);
        this.router.navigateByUrl("/login");
    };
    AuthenticationService.prototype.logout = function () {
        this.token = "";
        localStorage.removeItem('currentUser');
        localStorage.removeItem('ANMS-AUTH');
        this.store.dispatch(new ActionAuthLogout());
        location.reload();
    };
    AuthenticationService.prototype.clearLoginStorage = function () {
        this.localStorageService.removeItem('currentUser');
        this.localStorageService.setItem("AUTH", "{ isAuthenticated: false }");
    };
    return AuthenticationService;
}());
export { AuthenticationService };
var User = /** @class */ (function () {
    function User(Email, PersonalNumber, username, password) {
        this.Email = Email;
        this.PersonalNumber = PersonalNumber;
        this.username = username;
        this.password = password;
    }
    return User;
}());
export { User };
var PasswordReset = /** @class */ (function () {
    function PasswordReset(Email, Password, ConfirmPassword, Code) {
        this.Email = Email;
        this.Password = Password;
        this.ConfirmPassword = ConfirmPassword;
        this.Code = Code;
    }
    return PasswordReset;
}());
export { PasswordReset };
