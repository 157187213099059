import { Component, OnInit, ViewChild } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatRadioModule, MatRadioButton, MatCheckboxChange } from '@angular/material';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../../core/interfaces/ICompany';
import { IContact } from '../../core/interfaces/IContact';
import { IInvoice } from '../../core/interfaces/IInvoice';
import { IFile } from '../../core/interfaces/IFile';
import { IDeduction } from '../../core/interfaces/invoice/IDeduction';
import { IAllowance } from '../../core/interfaces/invoice/IAllowance';
import { EditCustomerDialogComponent } from '../../shared/customer/EditCustomerDialog.Component';
import { EditDeductionDialogComponent } from '../../static/invoice-tool/deduction/edit-deduction-Dialog.Component';
import { EditAllowanceDialogComponent } from '../../static/invoice-tool/allowance/edit-allowance-Dialog.Component';
import { EditContactDialogComponent } from '../../shared/contact/EditContactDialog.Component';
import { ModalSpinnerDialogComponent } from '../../shared/modal-spinner/ModalSpinnerDialog.Component';
import { InvoiceToolCalendarDialogComponent } from './invoice-tool.calendarDialog.Component';
import { InvoicePreviewDialogComponent} from './invoice-tool.invoicePreviewDialog.Component'
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { CurrencyService } from '../../core/services/CurrencyService';
import { UserService } from '../../core/services/UserService'; 
import { LoadingBarService } from '@ngx-loading-bar/core';
import { IAccountSettings } from '../../core/interfaces/user/IAccountSettings';
import { IUser } from '../../core/interfaces/user/IUser';
import { AddPaymentRecieversDialogComponent } from './add-payment-recievers/add-payment-recievers-Dialog.Component';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FeeService } from '../../core/services/FeeService';
import { AuthenticationService } from '../../core/services/AuthenticationService';
import { OrganizationService } from '../../core/services/OrganizationService';

@Component({
    selector: 'combined-invoice-tool',
  templateUrl: './combined-invoice-tool.component.html',
  styleUrls: ['./combined-invoice-tool.component.scss']
})


export class CombinedInvoiceToolComponent implements OnInit {
  panelOpenStateSalaryShares = false;
  panelOpenStateAllowances = false;
  panelOpenStateDeductions = false;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  versions = env.versions;
  id: number;
  paymentRecievers: Array<any>;
  companies = [];
  currencies = [];
  hoursWorkedTotal: number = 0;
  TotalInvoiceAmount: number = 0;
  ref: IContact = { Id: 0, Address: "", City: "", Email: "", Name: "", NameLocked: false, Phone: "", Zip: "", IDNumber: "" };
  invoice = {
    Id: 0,
    Allowances: [],
    Commission: 0,
    ParentInvoice: true,
    SubInvoice: false,
    ReferenceText: null,
    ServiceFee: 0,
    ServiceFeeShare: { IsActive: false, Percentage: 0, InvoiceSenderServiceFeeFactor: 0 },
    InvoiceFee: 0,
    ExpressPayOut: false,
    RotDrivingFee: 3,
    ServiceFeePaidByReciever: true,
    RotDrivingFeeTotalAmount: 0,
    IsRot: false, IsRut: false,
    IsRotRut: false,
    RotProperty: null,
    RotDrivingFeeKm: 0,
    Marking: '',
    CreditInvoiceFor: null,
    ExpressPayout: false,
    Status: { Name: 'Utkast', TechnicalName: 'Draft', Order: 0 },
    User: { Id: "0" },
    InvoiceGroups: [],
    Messages: [],
    Message: undefined,
    TravelExpenses: { Reciepts: [], TravelReportTotalAmount: 0, DriveJournalTotalAmount: 0 },
    PaymentTerm: "30",
    SalaryShares: [],
    selectedReference: "",
    ShowDeductions: false,
    ShowMileDeduction: false,
    TravelDistance: undefined,
    Deductions: [],
    invoiceItemGroup: [],
    Reference: this.ref,
    RecieverEmail: '',
    termsAgreed: false,
    Customer: { Id: 0, Name: '', Address: '', Zip: '', City: '', Co: '', GlnNumber: null, Country: { Id: null, Name: null }, Reference: {}, InvoiceEmail: '', Contacts: [], IsCompany: false },
    Currency: { Id: "1", Name: "SEK" },
    InvoiceSendMethodEmail: true,
    InvoiceSendMethodMail: false,
    InvoiceSendType: 1, action: '',
    PensionSavingsFactor: 0,
    PensionSavingsAmount: 0,
    RecieverCompanyName: '',
    RecieverAddress1: '',
    RecieverZip: '',
    RecieverCity: '',
    RecieverCountry: '',
    SelectedCurrency: 'SEK',
    FeePaidByReciever: true,
    TotalInvoiceAmount: 0,
    UserSettings: { Id: 0, Guid: null }
  };
    //Id: 0, Allowances: [], ParentInvoice: true, SubInvoice: false, ReferenceText: null, ServiceFee: 0, InvoiceFee: 0, ExpressPayOut: false, RotDrivingFee: 3, ServiceFeePaidByReciever: true, RotDrivingFeeTotalAmount: 0, IsRot: false, IsRut: false, IsRotRut: false, RotProperty: null, RotDrivingFeeKm: 0, Marking: '', CreditInvoiceFor: null, ExpressPayout: false, Status: { TechnicalName: 'Draft', Order: 0 }, User: { Id: "0" }, InvoiceGroups: [], Messages: [], Message: undefined,TravelExpenses: { Reciepts: [], TravelReportTotalAmount: 0, DriveJournalTotalAmount: 0 },PaymentTerm: "30", SalaryShares: [], selectedReference: "", ShowDeductions: false, ShowMileDeduction: false, TravelDistance: undefined, Deductions: [], invoiceItemGroup: [], Reference: this.ref, RecieverEmail: '', termsAgreed: false, Customer: { Id: 0, Name: '', Address: '', Zip: '', City: '', Co: '', GlnNumber: null, Country: { Id: null, Name: null }, Reference: {}, InvoiceEmail: '', Contacts: [], IsCompany: false }, Currency: { Id: "1", Name: "SEK" }, InvoiceSendMethodEmail: true, InvoiceSendMethodMail: false, InvoiceSendType: 1, action: '', RecieverCompanyName: '', RecieverAddress1: '', RecieverZip: '', RecieverCity: '', RecieverCountry: '', SelectedCurrency: 'SEK', FeePaidByReciever: true, TotalInvoiceAmount: 0, UserSettings: { Id: 0, Guid: null }  };
  invoiceQuickGroupTemplate = {
    Description: '',
    Vat: "25",
    Deductions: [],
    Allowances: [],
    PaymentReciever: {
      Id: 0, Name: null,
      PersonalNumber: null,
      OrgNumber: null,
      IsCompany: false,
      LastLogin: null,
      User: null
    },
    VatNoSwe: "0",
    EmploymentType: {
      Id: null,
      Name: null,
      TechnicalName: null
    },
    TimeReportStatus: {
      DisplayName: "Ingen tid",
      Id: "3", Name: "NoInfo",
    }, Type: {
      Value: "1",
      Name: "Arbetad tid"
    },
    rowTypeSelected: "1",
    InvoiceRows: [],
    PensionSavingsPercent: 0,
    PensionSavingsAmount: 0,
    PensionSavingsTotalAmount: 0
  };
  invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));
  vatList = [];
  invoiceShareTypes = [];
  addToInvoiceList = [{ Name: 'Ja', Value: true }, { Name: 'Nej', Value: false }];
  paymentTerms = [10,15,20,25,30];
  isLoading = true;
  isAdmin = false;
  feePaidByReciever: boolean = true;
  feePaidBySender: boolean = false;
  showValidationSummary: boolean = false;
  submitType: number = 0;
  invoiceSendTypeMail: boolean = false;
  invoiceSendTypeEmail: boolean = true;
  DueDate: number;
  today: Date;
  PaymentTermInt: any;
  //statusOrder = 0;
  //DiscountCode = "Corona2020";
  ReadOnlyCheckbox: boolean;
  resultMarking: number;
  resultDescription: number;
  txt: any;
  text: any;
  str: any;
  sumNetExpenses: number = 0;
  sumFees: number = 0;
  preliminaryPayout: number = 0;
  ExpressPayoutLocal: boolean = false;
  dialogSpinner: any = null;
  RotDrivingFee: number = 3;
  userIsInvoiceReceiver: boolean = false;
  public searchAccountValue: string;
  user: IUser;
  customerName: any;
  ErrorMessageTab: any;
  ErrorMessageSend: any;
  personalOrOrgNumberValid: boolean = false;
  filteredStates: Observable<any[]>;
  selectedMember: any;
  selectedMemberObject: any;
  selectedMemberName: any;
  memberSearchTerm: string;
  states: State[] = [];
  userCtrl: FormControl;
  serviceFeeFactor: number = 0.02;
  originalServiceFeeFactor = 0.02;
  serviceFeeFactorUpdated: number = 0;
  serviceFeeShare: number = 0;
  memberServiceFeeShare: number = 0;
  commissionPercent: number = 0;
  serviceFeeAddedToInvoice: number = 0;
  newCommissionPercent: number = 0;
  commissionFactor: number = 0;
  pensionSavingsFactor: number = 0;
  pensionSavingsAmount: number = 0;
  pensionSavingsPercent: number = 0;
  pensionSavingsTaxFactor = 0.2426;
  PensionSavingsTotalAmount = 0;
  globalPensionSavingsPercent: any = 0;
  errorMessagePension: string = "";
  currentUserOrganizationId: number = 0;
 
  constructor(
      private router: Router,
      private _location: Location,
      private route: ActivatedRoute,
      public dialog: MatDialog,
      public authenticationService: AuthenticationService,
      private companyService: CompanyService,
      private invoiceService: InvoiceService,
      private currencyService: CurrencyService,
      private organizationService: OrganizationService,
      private loadingService: LoadingBarService,
      private userService: UserService,
      private feeService: FeeService
  ) {

  }

  ngOnInit() {

    this.openLoadingSpinner();
    this.loadingService.start();
    this.currentUserOrganizationId = this.authenticationService.getCurrentUserOrganization() != null ? this.authenticationService.getCurrentUserOrganization().Id : 0;

    this.loadCurrencies();
    this.resultDescription = 0;
    this.resultMarking = 0;
    this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" })
    
    this.isAdmin = this.userService.userHasRole("admin");
    this.PaymentTermInt = parseInt(this.invoice.PaymentTerm);
    this.today = new Date();
    this.DueDate = new Date().setDate(this.today.getDate() + this.PaymentTermInt);
    this.ReadOnlyCheckbox = true;
    this.loadUser();
    
    this.route.params.subscribe(params => {
      this.id = params['id'] ? params['id'] : 0;
      this.loadInvoice(this.id);
      this.getPaymentRecievers();
    });

    if (this.isAdmin) {
      this.ReadOnlyCheckbox = false;
    }

    this.loadingService.complete();
    this.isLoading = false;
    this.updateGroupAmounts();
  }

  getPaymentRecievers() {
    this.organizationService.getPaymentRecievers(this.currentUserOrganizationId)
      .subscribe(response => this.paymentRecievers = response);
  }

  sendMessage(message: string)
  {
      this.invoiceService.sendMessage(message, this.id)
          .subscribe(
          response => this.messageSent(response),
          error => console.log(error));
  }

  cancel() {
        if (confirm("Vill du lämna utan att spara några ändringar i fakturan?")){
          this._location.back();
        }
  }

  messageSent(message: any)
  {
      this.invoice.Message = undefined;
      this.invoice.Messages.push(message);
  }

  currencyChanged()
  {
      for (var i = 0; i < this.currencies.length; i++)
      {
          var curr = this.currencies[i];
          if (curr.Id == this.invoice.Currency.Id)
          {
              this.invoice.Currency.Name = curr.Name;
          }
      }
  }

  shareTypeChanged(event: any, share: any) {

      if (event == 1)
      {
          share.Salary = 0;
      }
      else
      {
          share.Share = 0;
      }
      
  }
    
  SearchAccount(term: string, group: any) {
    
    if (term == null || term == "") {
      group.PaymentReciever = null;
      return;
    }

    this.userService.getUserByPersonalOrOrgNumber(term)
      .subscribe(
        response => this.onSearchAccountCompleted(response,group),
        error => console.log(error));
  }

  loadUser() {
    this.userService.getCurrentUser()
      .subscribe(value => this.userLoaded(value),
        error => console.log(error)
      );
  }

  userLoaded(user: IUser) {
    this.user = user;   

    this.setCommission();
    this.loadMemberServiceFeeFactor();
    this.dialogSpinner.close();
  }

  setCommission() {
    this.commissionPercent = this.user.AccountSettings.Commission * 100; //Preset commission from settings
    this.newCommissionPercent = this.commissionPercent; //Customizable setting
    this.invoice.Commission = this.commissionPercent;
    
  }

  updateCommission(commission: number) {
    this.newCommissionPercent = commission;
    this.invoice.Commission = this.newCommissionPercent / 100;
     
    this.updateGroupAmounts(null, commission); 
    
  }

  onSearchAccountCompleted(response: IAccountSettings, group: any) {

    group.PaymentReciever = response;
    
    //this.searchAccountResult = response;
    //console.log('UserId: ' + this.searchAccountResult.Id);
  }

  openLoadingSpinner() {
    this.dialogSpinner = this.dialog.open(ModalSpinnerDialogComponent, this.configSpinner);
  }

  // WordCounters
  wordCountDescription() {
    this.resultDescription = this.invoiceQuickGroup.Description.length;

    //this.txt = this.invoiceQuickGroup.Description.value;
    //this.text = this.txt.split(".");
    //this.str = this.text.join('.</br>');
  }

  wordCountMarking() {
    this.resultMarking = this.invoice.Marking.length;
  }

  loadInvoice(id: number)
  {
    this.organizationService.getInvoiceById(this.currentUserOrganizationId,id)
          .subscribe(
          response => this.onGetInvoiceCompleted(response),
            error => console.log(error));
  }
  
  loadCurrencies() {
      this.currencyService.getCurrencies()
          .subscribe(
          response => this.currencies = response,
          error => console.log(error));
  }

  loadMemberServiceFeeFactor() {
    
    var userGuid = "";

    if (this.isAdmin) {
      if (this.invoice.UserSettings != null && this.invoice.UserSettings.Guid != "" && this.invoice.UserSettings.Guid != null) {
        //If admin is creating a combined invoice, use the invoice sender custom fee
        userGuid = this.invoice.UserSettings.Guid;
      }
    }
    else if (this.user != null) {
      userGuid = this.user.Guid;
    }
    
    this.feeService.getMemberServiceFee(userGuid).subscribe(
      response => {
        this.serviceFeeFactor = response;
        this.originalServiceFeeFactor = response;
      },
      error => console.log(error)
    );
    
    //If share is 0, it is equal to 100% paying of the service fee
    if (this.invoice.ServiceFeeShare != null && this.invoice.ServiceFeeShare.Percentage == 0) {
      this.invoice.ServiceFeeShare.Percentage = 100;
    }
    else {
      if (this.invoice.ServiceFeeShare != null) {
        this.serviceFeeShare = this.invoice.ServiceFeeShare.Percentage;
      }
    }
    
    this.updateInvoiceServiceFee();

  }

  updateInvoiceServiceFee() { 
    
    if (this.invoice.ServiceFeeShare == null) {
      const newServiceFeeShare = {
        Percentage: 0,
        InvoiceSenderServiceFeeFactor: 0,
        IsActive: false
      };

      this.memberServiceFeeShare = 100;
      this.invoice.ServiceFeeShare = newServiceFeeShare;
    }

    if (this.invoice.ServiceFeeShare != null && this.invoice.ServiceFeeShare.IsActive) {

      //var anySelfEmployed = this.checkIfAnySelfEmployed();      
        
        var serviceFeeShareFactor = this.serviceFeeShare / 100;
        this.invoice.ServiceFeeShare.Percentage = this.serviceFeeShare;

        //Save the original invoice fee to be used in salary calulations for this invoice
        if (this.originalServiceFeeFactor > 0) {
          this.invoice.ServiceFeeShare.InvoiceSenderServiceFeeFactor = this.originalServiceFeeFactor; 
        }
    
        //Calculate fee used for this invoice
        this.serviceFeeFactor = this.invoice.ServiceFeeShare.InvoiceSenderServiceFeeFactor * serviceFeeShareFactor;

        //Calculate member share for view
        this.memberServiceFeeShare = 100 - this.serviceFeeShare;
        
        this.updateTotalInvoiceAmount();
      }
  }

  getGroupTotalHours(group: any)
  {
      var totalMinutes = 0;

      for (var i = 0; i < group.InvoiceRows.length; i++)
      {
          totalMinutes += parseInt(group.InvoiceRows[i].HoursWorked) * 60;
          totalMinutes += parseInt(group.InvoiceRows[i].MinutesWorked);
      }
      
      return this.time_convert(totalMinutes);
  }
  
  time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    if (minutes > 0)
    {
        return hours + " h " + minutes + " min";
    }
    else
    {
        return hours + " h";
    }
     
  }

  onGetInvoiceCompleted(invoice: any)
  { 
    this.invoice = invoice;
      
    this.invoice.Currency.Id = this.invoice.Currency.Id.toString();

    if (this.invoice.Allowances == null)
      this.invoice.Allowances = [];
    
    this.invoice.ServiceFeeShare = this.invoice.ServiceFeeShare == null ? { IsActive: false, Percentage: 0, InvoiceSenderServiceFeeFactor: 0 } : this.invoice.ServiceFeeShare;

      
    this.setTotalHoursWorked();
    this.invoice.PaymentTerm = (this.invoice.PaymentTerm == undefined || this.invoice.PaymentTerm == "0") ? "30" : this.invoice.PaymentTerm.toString();
    this.getCompanies();
      
    if (this.invoice.TravelDistance > 0)
    {
        this.invoice.ShowMileDeduction = true;
    }

    if (this.invoice.Deductions != null && this.invoice.Deductions.length > 0)
    {
      this.invoice.ShowDeductions = true;    

      for (let deduction of this.invoice.Deductions) {
          deduction.IncludeInInvoice = deduction.IncludeInInvoice.toString();
      }
    }
      
    if (this.invoice.FeePaidByReciever == undefined || this.invoice.FeePaidByReciever)
    {
        this.feePaidByReciever = true;
        this.feePaidBySender = false;
    }
    else
    {
        this.feePaidBySender = true;
        this.feePaidByReciever = false;
    }

    if (this.invoice.Id != 0) {
      this.updateCommission(this.invoice.Commission * 100);
    }
    
    this.updateTotalInvoiceAmount();
    this.loadMemberServiceFeeFactor();    
    this.setRecieverAddress();

    this.updateGroupAmounts(null, this.newCommissionPercent);
  }

  // Gets the duedate for invoice
  getDueDate() {
    this.PaymentTermInt = parseInt(this.invoice.PaymentTerm);
    this.today = new Date();
    this.DueDate = new Date().setDate(this.today.getDate() + this.PaymentTermInt);
  }

  getAllowanceTypeName(id: string)
  {
      if (id == "1") {
          return "Milersättning";
      }
      else if (id == "2") {
          return "Traktamente inrikes"
      }
      else if (id == "3") {
          return "Traktamente utrikes"
      }
  }

  onAmountUpdated() {
    this.updateTotalInvoiceAmount();
    this.updateGroupAmounts();
  }

  //Update pension savings amount in each invoice group (salary reciever)
  updateGroupAmounts(newPensionPercent: any = null, commissionPercent: any = null) {         
    
    for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {
      var group = this.invoice.InvoiceGroups[i];
      
      
      if (this.invoice.Id == 0) {
        group.PensionSavingsAmount = 0;
        group.PensionSavingsPercent = this.globalPensionSavingsPercent; 
        group.PensionSavingsTotalAmount = 0; 
      }

      if (commissionPercent != null) {
        group.commissionAmount = group.Amount * (commissionPercent / 100);
      }

      if (newPensionPercent != null) {
        group.PensionSavingsFactor = newPensionPercent / 100;
      }

      this.updateSingleGroupPensionAmounts(group, null, null);
      this.updateGroupTotalAmounts(group);
      this.invoice.InvoiceGroups[i] = group;
    }
        
  }

  updateSingleGroupPensionAmounts(group: any, savingsPercent: any, savingsAmount: any) {
    
    //Deduct commision first
    
    if (group.commissionAmount == null || group.commissionAmount <= 0) {
      group.commissionAmount = 0;
    }

    var amountCommDeducted = group.Amount - group.commissionAmount;
    group.PensionSavingsPercent = 0;

    if (savingsPercent == null && savingsAmount == null) {      
      savingsPercent = group.PensionSavingsFactor * 100;
      if (group.PensionSavingsAmount) {
        savingsAmount = group.PensionSavingsAmount;
      }
    }

    if (savingsPercent != null && savingsPercent > 0) {
      group.PensionSavingsFactor = savingsPercent / 100;

      if (savingsPercent > 0) {
        group.PensionSavingsPercent = savingsPercent;
      }
      else {
        group.PensionSavingsPercent = group.PensionSavingsFactor * 100;
      }
      group.PensionSavingsAmount = Math.round(amountCommDeducted * group.PensionSavingsFactor) * 100 / 100;
    }
    else if (savingsAmount != null || group.PensionSavingsAmount > 0) {
      group.PensionSavingsAmount = savingsAmount;
      group.PensionSavingsFactor = 0;
      group.PensionSavingsPercent = 0;
    }
    
    group.PensionSavingsTotalAmount = Math.round((group.PensionSavingsAmount * 1.2426) * 100 / 100); 

    group.partialAmount = group.Amount - group.PensionSavingsTotalAmount - group.commissionAmount;
    group.totalAmountInclNetExpenses = group.Amount;
    
    var partialAmountExclServiceFee = group.Amount * 0.98;
        
    if (group.Amount != null && group.PensionSavingsTotalAmount >= partialAmountExclServiceFee) {
      group.errorMessagePension = "Tjänstepensionsavsättningen (inkl skatt) överstiger det fakturerade beloppet minus avgifter";
    }
    else {
      group.errorMessagePension = "";
    }

  }

  updateGroupTotalAmounts(group: any) {

    if (group.Allowances != null) {
      for (var a = 0; a < group.Allowances.length; a++) {
        var allowance = group.Allowances[a];

        group.totalAmountInclNetExpenses += allowance.Sum;
      }
    }

    if (group.Deductions != null) {
      for (var a = 0; a < group.Deductions.length; a++) {
        var deduction = group.Deductions[a];

        group.totalAmountInclNetExpenses += deduction.Amount;
      }
    }
    
  }

  updateSumNetExpenses() {

    this.sumNetExpenses = 0;

    //Sums all deductions
    if (this.invoice.Deductions != null) {
      for (var i = 0; i < this.invoice.Deductions.length; i++) {
        this.sumNetExpenses += this.invoice.Deductions[i].Amount;
      }
    }

    //Sums all allowances
    if (this.invoice.Allowances != null) {
      for (var i = 0; i < this.invoice.Allowances.length; i++) {
        this.sumNetExpenses += this.invoice.Allowances[i].Sum;
      }
    }
  }
    
  calculatePreliminaryPayout()
  {
    var invoiceFee = 0;

    this.updateSumNetExpenses();

    //If service fee is paid by reciever, it is added to invoice, otherwise calculated from salary on member
           
    //Calculate invoice fee
    if (this.invoice.FeePaidByReciever == false) {
      invoiceFee = 29;
    }

    this.sumFees = this.invoice.ServiceFee + invoiceFee;
    this.preliminaryPayout = this.invoice.TotalInvoiceAmount - this.sumFees;

  }

  saveDraftValidates() {
    if (this.invoice.Status.TechnicalName != 'Draft') {
      this.ErrorMessageSend = 'Fakturan kan inte redigeras efter att den skickats';
      return false;
    }

  }

  sendValidates() {

    //You can only send if the status is Draft
    if (this.invoice.Status.TechnicalName != 'Draft') {
      this.ErrorMessageSend = 'Den här fakturan har skickats';
      return false;
    }

    //Customer is mandatory 
    if (!this.invoice.Customer.Id) {
      this.ErrorMessageSend = 'Du behöver lägga till en kund';
      return false;
    }

    //Customer reference is mandatory
    if (this.invoice.Customer.IsCompany && !this.invoice.Reference.Id) {
      this.ErrorMessageSend = 'Du behöver lägga till en kontaktperson till din kund';
      return false;
    }

    //Only credit invoices can be 0 or less
    if ((this.invoice.TotalInvoiceAmount <= 0 || this.TotalInvoiceAmount <= 0) && this.invoice.CreditInvoiceFor == null) {
      this.ErrorMessageSend = 'Du måste fakturera mer än 0 kr i punkt nr 2';
      return false;
    }
    
    for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {
      var group = this.invoice.InvoiceGroups[i];

      var paymentReciever = (group.PaymentReciever.FirstName + " " + group.PaymentReciever.LastName).toString();

      var partialAmountExclServiceFee = group.Amount * 0.98;

      if (group.PensionSavingsTotalAmount >= partialAmountExclServiceFee) {
        this.ErrorMessageSend = "Tjänstepensionsavsättningen (inkl skatt) på " + paymentReciever + " överstiger det fakturerade beloppet minus avgifter";
        return false;
      }
    }

    //You need a reciever email if sendtype is by email
    if (this.invoice.InvoiceSendType == 1 && !this.invoice.RecieverEmail) {
      this.ErrorMessageSend = 'Du har valt e-post som leverenssätt, men mottagaren saknar e-postadress';
      return false;
    }

    //Terms of use must be checked
    if (!this.invoice.termsAgreed) {
      this.ErrorMessageSend = 'Du måste godkänna användarvillkoren';
    }

    else {
      this.ErrorMessageSend = '';
      return true;
    }

    
  }

  getNewSender(invoice: any) {

    this.invoice.ParentInvoice = true;

    if (this.canUpdateInvoiceDraft())
    {

      this.isLoading = true;

      invoice.Status = { Name: 'Utkast' };

      if (this.selectedMemberObject != null) {
        this.invoice.UserSettings = this.selectedMemberObject;
      }

      if (invoice.Id == undefined || invoice.Id == 0) {
        this.invoiceService.createInvoice(invoice, 'Fakturan skapad!', undefined)
          .subscribe(
            response => this.onGetNewSenderSaved(response),
            error => console.log(error));
      }
    }

    else {
      this.submitType = 1;
      this.showValidationSummary = true;
    }
  }

  fileChange(event: any, file: any) {
      
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
          let f: File = fileList[0];

          this.invoiceService.uploadDocument(f, file.Attachment.Guid)
              .subscribe(
              response => this.onDocumentSaved(response),
              error => console.log(error));

      }
  }

  addPaymentRecievers() {

    var config = JSON.parse(JSON.stringify(this.config));
    config.disableClose = true;

    let dialogRef = this.dialog.open(AddPaymentRecieversDialogComponent, config);

    dialogRef.afterClosed().subscribe((response) => {

      if (response) {
            
        for (var i = 0; i < response.PaymentRecievers.length; i++) {

          var paymentReciever = response.PaymentRecievers[i];
          var newGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroup));

          // Get the payment reciever data
          
          newGroup.PaymentReciever.FirstName = paymentReciever.FirstName;
          newGroup.PaymentReciever.LastName = paymentReciever.LastName;

          newGroup.PaymentReciever.PersonalNumber = paymentReciever.PersonalNumber;
          newGroup.PaymentReciever.Guid = paymentReciever.Guid;
          newGroup.EmploymentType.Id = response.EmploymentTypeId;
          newGroup.Description = response.Description;
          newGroup.Amount = response.Amount;
          newGroup.InvoiceRows = [];

          this.saveQuickRow(newGroup, false);
        }

      }

      this.updateTotalInvoiceAmount();

    });

  }

  uploadAddFile(event: any, file: any)
  {
      
      if (event.target.files.length > 0) {

          let fileList: FileList = event.target.files;
          let f: File = fileList[0];

          if (Array.isArray(file)) {
              
              var newFile = { Guid: this.newGuid(), Name: f.name };
              file.push(newFile);

              this.invoiceService.uploadDocument(f, newFile.Guid)
                  .subscribe(
                  response => this.fileUploaded(response, newFile),
                  error => console.log(error));

          }
          else
          {
              if (file.Guid == undefined) {
                  file.Guid = this.newGuid();
              }
              
              this.invoiceService.uploadDocument(f, file.Guid)
                  .subscribe(
                  response => this.fileUploaded(response, file),
                  error => console.log(error));

          }
          
      }
  }

  fileUploaded(response: any, file: any)
  {
      file.Guid = response.Guid;
      file.Url = response.Url;
  }

  deleteReciept(guid: string)
  {

      for (var i = 0; i < this.invoice.TravelExpenses.Reciepts.length; i++)
      {
          if (this.invoice.TravelExpenses.Reciepts[i].Guid == guid)
          this.invoice.TravelExpenses.Reciepts.splice(i, 1);
      }
  }

  newGuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
  }

  showDocument(url: string)
  {
      window.open(url);
  }

  editDeduction(invoiceGroup: any, sender: string, deduction: IDeduction)
  {
      if (deduction !=  null)
      {
          this.config.data.deduction = deduction;        

          if (this.config.data.deduction.AttachmentReciept == null)
              this.config.data.deduction.AttachmentReciept = <IFile>{ Id: 0, Guid: null }
          
          if (this.config.data.deduction.Attachment == null)
          this.config.data.deduction.Attachment = <IFile>{ Id: 0, Guid: null }
        
        this.config.data.deduction.GroupId = invoiceGroup.Id;
      }
      else
      {
        this.config.data.deduction = { Id: this.newGuid(), GroupId: invoiceGroup.Id, Attachment: <IFile>{ Id: 0, Guid: null }, AttachmentReciept: <IFile>{ Id: 0, Guid: null }, VatPercentage: '25', TravelExpense: false, AddedToInvoice: false } as IDeduction;
          
         if (sender == "addedToInvoice") {
           this.config.data.deduction.AddedToInvoice = true;
         }
      }

      let dialogRef = this.dialog.open(EditDeductionDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: IDeduction) => {
      
      if (result != null) {
        
        // Loop all groups
        for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {

          var currentGroup = this.invoice.InvoiceGroups[i];

          // Current group beeing edited
          if (currentGroup.Id == result.GroupId) {

            // Loop all deductions on current group
            for (var d = 0; d < currentGroup.Deductions.length; d++) {
              var currentDeduction = currentGroup.Deductions[d];

              // Existing deduction, overwrite
              if (currentDeduction.Id == result.Id) {
                currentGroup.Deductions[d] = result;                
                this.updateTotalInvoiceAmount();
                this.updateGroupAmounts();
                return;
              }
            }
            // New deduction, add to group
            currentGroup.Deductions.push(result);
            
          }
          this.updateTotalInvoiceAmount();
          this.updateGroupAmounts();
        }                
      }
      dialogRef = null;
      
      });
  }

  deleteDeduction(group, deduction: any)
  {
    deduction.Amount = Math.round(deduction.Amount);

    if (confirm("Vill du radera avdraget " + deduction.Amount + " kr ?")) {
      for (var i = 0; i < group.Deductions.length; i++) {
        var currentDeduction = group.Deductions[i];
        if (currentDeduction.Id == deduction.Id) {
          group.Deductions.splice(i, 1);
          this.updateTotalInvoiceAmount();
          this.updateGroupAmounts();
          return;
        }
      }

      this.updateSumNetExpenses();
      this.updateGroupAmounts();
    }
  }

  editAllowance(invoiceGroup: any, sender, allowance: IAllowance) {    
    
      if (allowance != null) {

          this.config.data.allowance = allowance;

          if (this.config.data.allowance.AttachmentReciept == null)
              this.config.data.allowance.AttachmentReciept = <IFile>{ Id: 0, Guid: null }

          if (this.config.data.allowance.Attachment == null)
          this.config.data.allowance.Attachment = <IFile>{ Id: 0, Guid: null }

        this.config.data.allowance.GroupId = invoiceGroup.Id;
      }
      else {
        this.config.data.allowance = { Id: this.newGuid(), GroupId: invoiceGroup.Id, Attachment: <IFile>{ Id: 0, Guid: null }, AttachmentReciept: <IFile>{ Id: 0, Guid: null }, VatPercentage: '25' } as IAllowance;

        if (sender == "addedToInvoice") {
          this.config.data.allowance.AddedToInvoice = true;
        }
      }

      let dialogRef = this.dialog.open(EditAllowanceDialogComponent, this.config);
    
      dialogRef.afterClosed().subscribe((result: IAllowance) => {
        
        if (result != null) {
           
          // Loop all groups
          if (this.invoice.InvoiceGroups != null) {
            for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {

              var currentGroup = this.invoice.InvoiceGroups[i];

              // Current group beeing edited
              if (currentGroup.Id == result.GroupId) {                
                // Loop all deductions on current group
                if (currentGroup.Allowances != null) {
                  for (var d = 0; d < currentGroup.Allowances.length; d++) {
                    var currentAllowance = currentGroup.Allowances[d];

                    // Existing deduction, overwrite
                    if (currentAllowance.Id == result.Id) {
                      currentGroup.Allowances[d] = result;
                      this.updateTotalInvoiceAmount();
                      this.updateGroupAmounts();
                      return;
                    }
                  }
                }                
                // New deduction, add to group 
                currentGroup.Allowances.push(result);
                this.updateTotalInvoiceAmount();
                this.updateGroupAmounts();
              }
            }
          }
        }
        
        dialogRef = null;
        
      });
  }

  deleteAllowance(group, allowance: IAllowance) {
    
    if (confirm("Vill du radera traktamentet " + allowance.Sum + " kr ?")) {
      for (var i = 0; i < group.Allowances.length; i++) {
        var currentAllowances = group.Allowances[i];
        if (currentAllowances.Id == allowance.Id) {
          group.Allowances.splice(i, 1);
          this.updateTotalInvoiceAmount();
          this.updateSumNetExpenses();
          this.updateGroupAmounts();

          return;
        }
      }
    }
  }

  deleteShare(shareIndex: number) {
      this.invoice.SalaryShares.splice(shareIndex, 1);
  }

  onDocumentSaved(attachment: any)
  {   
      for (var i = 0; i < this.invoice.Deductions.length; i++)
      {
          var doc = this.invoice.Deductions[i];

          console.log(doc);

          if (doc.Attachment.Guid == attachment.Guid)
          {
              doc.Attachment.Url = attachment.Url;
          }
      }

  }

  saveDraft() { 
    
    this.invoice.FeePaidByReciever = true;
    this.invoice.ParentInvoice = true;

    this.calculatePreliminaryPayout();


    if (!this.canUpdateGeneral()) {

      this.invoiceService.updateInvoice(this.invoice, 'Fakturan uppdaterad!', undefined)
        .subscribe(
          response => this.onDraftSaved(response),
          error => console.log(error));
    }
    else if (this.canUpdateInvoiceDraft()) {

      this.isLoading = true;

      this.invoice.Status.TechnicalName = 'Draft';
      this.invoice.Status.Name = 'Utkast';

      if (this.selectedMemberObject != null) {
        this.invoice.UserSettings = this.selectedMemberObject;
      }

      if (this.invoice.Id == undefined || this.invoice.Id == 0) {
        this.organizationService.createInvoice(this.invoice, this.currentUserOrganizationId, 'Fakturan skapad!', undefined)
          .subscribe(
            response => this.onDraftSaved(response),
            error => console.log(error));
      }
      else {
        this.invoiceService.updateInvoice(this.invoice, 'Fakturan uppdaterad!', undefined)
          .subscribe(
            response => this.onDraftSaved(response),
            error => console.log(error));
      }

    }

    else {
      this.submitType = 1;
      this.showValidationSummary = true;
    }
  }

  send(invoice: any) {

    this.invoice.FeePaidByReciever = true;
    this.calculatePreliminaryPayout();
    this.invoice.ParentInvoice = true;
    
    if (this.canUpdateInvoice()) {
      
      this.isLoading = true;

      if (this.invoice.Status.TechnicalName == "Draft") {
        invoice.Status = { Name: 'Granskas' };
      }

      if (invoice.Id == undefined || invoice.Id == 0) {

        if (this.currentUserOrganizationId > 0) {

          // Organization invoice
          this.organizationService.createInvoice(invoice, this.currentUserOrganizationId, 'Fakturan skapad!', undefined)
            .subscribe(
              response => this.onDraftSaved(response),
              error => console.log(error));

        }
        else
        {
          // Private invoice
          this.invoiceService.createInvoice(invoice, 'Fakturan skapad!', undefined)
            .subscribe(
              response => this.onDraftSaved(response),
              error => console.log(error));
        }
      }
      else {
        
        this.invoiceService.updateInvoice(invoice, 'Fakturan uppdaterad!', undefined)
          .subscribe(
            response => this.onDraftSaved(response),
            error => console.log(error));
      }
    }
    else {
      this.submitType = 2;
      this.showValidationSummary = true;
    }
    
  }

  onDraftSaved(response: any)
  {
    if (this.isAdmin) {
      this.router.navigate(['/mypages/invoices']);
}
    else {
      this.router.navigate(['/organization/invoices']);
    }
  }

  onGetNewSenderSaved(response: any) {
    this.router.navigate(['/combined-invoice-tool/' + response.Id]);
  }
  
  canUpdateInvoice()
  {
      // You have to be able to save payout information untill it's been paid out
      let statuses = ["Draft", "UnderReview", "SentUnpaid", "SentReminder", "SentCollection","SentBailiff"];

      if (this.isAdmin) {
          return !this.isLoading;
      }
      else {
          return this.isLoading == false &&
              this.invoice.Customer.Id > 0 &&
              this.TotalInvoiceAmount >= 0 &&
              this.invoice.termsAgreed &&
              this.referenceValidates() &&
              statuses.includes(this.invoice.Status.TechnicalName)
      }
  }
  
  canUpdateInvoiceDraft() {

      let statuses = ["Draft", "UnderReview"];

      if (this.isAdmin) {
          return true;
      }
      else {
          return this.isLoading == false &&
              //this.invoice.Customer.Id > 0 &&
              //this.TotalInvoiceAmount > 0 &&
              //this.referenceValidates() &&
              statuses.includes(this.invoice.Status.TechnicalName)
      }
  }

  canUpdatePayout()
  {
      let statuses = ["Draft", "UnderReview", "SentUnpaid", "SentReminder", "SentCollection", "SentBailiff"];

      if (this.isAdmin) {
          return true;
      }
      else {
          return statuses.includes(this.invoice.Status.TechnicalName)
      }
  }

  canUpdateGeneral() {
    
    let statuses = ["Draft"];

    if (this.isAdmin) {
        return !this.isLoading;
    }
    else {
        return !this.isLoading && statuses.includes(this.invoice.Status.TechnicalName)
    }
    
  }

  referenceValidates()
  {
      // Reference required when customer is company
      return !(this.invoice.Customer.Id != undefined && this.invoice.Customer.IsCompany && this.invoice.Reference.Id == 0);
  }

  validPreview() {
      return true;
  }

  saveQuickRow(group: any, openEdit: boolean)
  {
    //If it reciever is a company, employment type is consultant
    if (group.PaymentReciever.IsCompany == true) {
      group.EmploymentType.TechnicalName = "Consultant";
      group.EmploymentType.Id = 3;
    }

    
    group.Id = this.newGuid();
    group.isNew = true;
    
    this.invoice.InvoiceGroups.push(group);
    this.setTotalHoursWorked();
    this.invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));
      
    if (openEdit)
    {
        this.editInvoiceCalendar(this.invoice.InvoiceGroups[this.invoice.InvoiceGroups.length-1]);
    }

    if (this.invoice.RecieverCountry != "Sverige") {
      group.Vat = "0";
    }
   
    this.invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));

    //Reset search box
    this.searchAccountValue = null;
    
    this.updateTotalInvoiceAmount();
    this.updateSingleGroupPensionAmounts(group, this.globalPensionSavingsPercent, null);

    if (group.EmploymentType.Id == 1) {
      group.EmploymentType.Name = "Bemanning"
    }
    if (group.EmploymentType.Id == 2) {
      group.EmploymentType.Name = "Egenanställd"
    }
    if (group.EmploymentType.Id == 3) {
      group.EmploymentType.Name = "Konsult"
    }
  }
  
  saveQuickRowValidates()
  {
    if (this.invoiceQuickGroup.Amount == 0 || this.invoiceQuickGroup.Amount == null)
    {
        return false;
    }

    if (this.invoiceQuickGroup.Description == null || this.invoiceQuickGroup.Description == '' || this.invoiceQuickGroup.Description == ' ')
    {
      return false;
    }

    if (this.invoiceQuickGroup.PaymentReciever.IsCompany == false && this.invoiceQuickGroup.EmploymentType.Id == null)
    {
      return false;
    }
    else
    {
        return true;
    }
  }

  editInvoiceCalendar(calendarGroup: any) {
      this.config.data.invoiceItemGroup = calendarGroup;
      this.config.data.status = this.invoice.Status.TechnicalName;
      this.config.data.customerCountryName = this.invoice.RecieverCountry;
      let dialogRef = this.dialog.open(InvoiceToolCalendarDialogComponent, this.config);

    

      dialogRef.afterClosed().subscribe((result: any) => {
          
          var updated = false;
          if (result != undefined)
          {

              for (var i = 0; i < this.invoice.InvoiceGroups.length; i++)
              {
                  if (this.invoice.InvoiceGroups[i].Id === result.Id)
                  {
                      if (result.action == 'delete') {
                          this.invoice.InvoiceGroups.splice(i, 1);
                      }
                      else {
                          this.invoice.InvoiceGroups[i] = result;
                      }
                    
                      if (this.invoice.RecieverCountry != "Sverige") {
                        this.invoice.InvoiceGroups[i].Vat = "0";
                      }

                      updated = true;
                      break;
                  }

                
                
              }

              if (!updated && this.invoice.action != 'delete')
                this.invoice.InvoiceGroups.push(result);

              //this.workCalendarList = result;
              this.setTotalHoursWorked();
              
          }

        this.updateTotalInvoiceAmount();
        this.updateGroupAmounts();
        

          dialogRef = null;
      });
  }

  preview(invoice: any) {
      
      var config = JSON.parse(JSON.stringify(this.config));
      config.height = '80%';
      config.width = '80%';
      config.data = this.invoice;
      config.data.TotalInvoiceAmount = this.TotalInvoiceAmount;


      let dialogRef = this.dialog.open(InvoicePreviewDialogComponent, config);

      dialogRef.afterClosed().subscribe((result: any) => {
          
          dialogRef = null;
      });
  }

  InvoiceSendMethodChange(type: any)
  {
      if (type == undefined || type == 0)
          type = 1;
      
      this.invoice.InvoiceSendType = type;
      
      if (type == 2) { // Mail
          this.invoice.InvoiceSendMethodMail = true;
          this.invoice.InvoiceSendMethodEmail = false;
      }
      else if (type == 1) { // Email
          this.invoice.InvoiceSendMethodMail = false; 
          this.invoice.InvoiceSendMethodEmail = true;
      }
      
  }

  setTotalHoursWorked()
  {
    this.hoursWorkedTotal = 0;
    this.TotalInvoiceAmount = 0;
      
    for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {

      this.TotalInvoiceAmount += this.invoice.InvoiceGroups[i].Amount;

      // Quick add don´t have rows
      if (this.invoice.InvoiceGroups[i].InvoiceRows != undefined) {

        for (var e = 0; e < this.invoice.InvoiceGroups[i].InvoiceRows.length; e++) {

            this.hoursWorkedTotal += parseFloat(this.invoice.InvoiceGroups[i].InvoiceRows[e].HoursWorked);
            this.hoursWorkedTotal += parseFloat(this.invoice.InvoiceGroups[i].InvoiceRows[e].MinutesWorked) / 60;
        }
      }
    }
  }

  updateTotalInvoiceAmount() {
    
    //this.updateInvoiceServiceFee();
    this.serviceFeeFactor = this.serviceFeeFactor;

    //Update total invoice amount when allowances or deductions are added to invoice (for customer to pay)
    this.setTotalHoursWorked();

    //Reset servicefee for each time total invoice amount is calculated
    this.invoice.ServiceFee = 0;
    var crewTotalAmount = 0;
    
    for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {
      if (this.invoice.InvoiceGroups[i].Deductions != null) {
        for (var d = 0; d < this.invoice.InvoiceGroups[i].Deductions.length; d++) {
          this.TotalInvoiceAmount += this.invoice.InvoiceGroups[i].Deductions[d].Amount;
        }
      }
      
      if (this.invoice.InvoiceGroups[i].Allowances != null) {
        for (var a = 0; a < this.invoice.InvoiceGroups[i].Allowances.length; a++) {
          this.TotalInvoiceAmount += this.invoice.InvoiceGroups[i].Allowances[a].Sum;
        }
      }
    }    
    
    for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {

      var groupAmount = this.invoice.InvoiceGroups[i].Amount;
      
      // If crew (bemanning) or shared service fee, add service fee to invoice
      if (this.invoice.InvoiceGroups[i].EmploymentType.Id == 1 || this.invoice.ServiceFeeShare.IsActive) {

        if (this.invoice.InvoiceGroups[i].Deductions != null) {
          for (var d = 0; d < this.invoice.InvoiceGroups[i].Deductions.length; d++) {
            groupAmount += this.invoice.InvoiceGroups[i].Deductions[d].Amount;
          }
        }

        if (this.invoice.InvoiceGroups[i].Allowances != null) {
          for (var a = 0; a < this.invoice.InvoiceGroups[i].Allowances.length; a++) {
            groupAmount += this.invoice.InvoiceGroups[i].Allowances[a].Sum;
          }
        }

        //Calculate service fee including deductions and allowances
        crewTotalAmount += groupAmount;
        this.serviceFeeAddedToInvoice += Math.round((groupAmount * this.serviceFeeFactor));

      }
      
    }
       
    this.invoice.TotalInvoiceAmount = this.TotalInvoiceAmount;
    this.invoice.ServiceFee = (crewTotalAmount / (1 - this.serviceFeeFactor)) * this.serviceFeeFactor; 

    this.calculatePreliminaryPayout();
  } 

isValidDate(year: number, month: number, day: number): boolean {
  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
}

  personalOrCompanyNumberValid(personalNumber: string): boolean {
    const value: string = personalNumber || '';

    // Check if the input is exactly 12 characters long
    if (value.length !== 12) {
      this.personalOrOrgNumberValid = false;
      return this.personalOrOrgNumberValid;
    }

    // Match YYYYMMDDNNNN
    const regex = /^\d{8}\d{4}$/;
    const match = value.match(regex);

    if (match) {
      // Extract the year, month, and day from the matched string
      const year = parseInt(value.substring(0, 4), 10);
      const month = parseInt(value.substring(4, 6), 10);
      const day = parseInt(value.substring(6, 8), 10);

      // Validate the date
      const valid = this.isValidDate(year, month, day);

      this.personalOrOrgNumberValid = valid;
      return this.personalOrOrgNumberValid;
    }

    this.personalOrOrgNumberValid = false;
    return this.personalOrOrgNumberValid;
  }

  //personalOrCompanyNumberValid(personalNumber: string) {
  //  const value: string = personalNumber || '';

  //  this.personalOrOrgNumberValid = value.match(/^\d{12}$/) != null || value.match(/^\d{6,8}[-|(\s)]{0,1}\d{4}$/) != null ? true : false;
        
  //  return this.personalOrOrgNumberValid;

  //}
  
  addCustomer() {
      
      var ownerId = this.invoice.User != undefined ? this.invoice.User.Id : undefined;

      // remove previous edit
      this.config.data.customer = undefined;
      this.config.data.customerTemplate = { Id: 0, User: { Id: ownerId }, Country: { Id: 1, Name: 'Sverige' }, IsCompany: true };
      let dialogRef = this.dialog.open(EditCustomerDialogComponent, this.config);

      dialogRef.afterClosed().subscribe((result: ICompany) => {

          if (result != undefined) {
              
              this.companies.push(result);
              
            // Remove this, check for empty
            this.invoice.Customer = { Address: result.Address, City: result.Address, Co: result.Co, GlnNumber: result.GlnNumber, Id: result.Id, InvoiceEmail: result.InvoiceEmail, Name: result.InvoiceEmail, Reference: result.Reference, Zip: result.Zip, Contacts: [], IsCompany: result.IsCompany, Country: { Id: null, Name: null } };

              // Set local data
              this.UpdateCompany(result, this.invoice.Customer);
              this.setInvoiceEmail(this.invoice.Customer.InvoiceEmail);
              this.setRecieverAddress();
            
          }
        
          dialogRef = null;
      });
  }

  addContact() {
    
      this.config.data.contact = undefined;
      this.config.data.companyId = this.invoice.Customer.Id;
      let dialogRef = this.dialog.open(EditContactDialogComponent, this.config);

      dialogRef.afterClosed().subscribe((result: IContact) => {

          if (result != undefined) {
              this.addSavedContact(result, this.invoice.Customer.Id);
          }

          dialogRef = null;
      });
  }

  editContact(contact: IContact) { 
    
    this.config.data.companyId = this.invoice.Customer.Id;
    this.config.data.reference = contact; 

      let dialogRef = this.dialog.open(EditContactDialogComponent, this.config);

      dialogRef.afterClosed().subscribe((result: IContact) => {
          
          this.invoice.Reference.Name = result.Name;
          this.invoice.Reference.Email = result.Email;
          this.invoice.Reference.Phone = result.Phone;
          
          for (var i = 0; this.invoice.Customer.Contacts.length; i++)
          {
              var contact = this.invoice.Customer.Contacts[i];
              if (contact.Id == result.Id)
              {
                  contact.Name = result.Name;
                  contact.Email = result.Email;
                  contact.Phone = result.Phone;
              }
          }
          
          dialogRef = null;
      });
  }

  setInvoiceEmail(email: string)
  {
      this.invoice.RecieverEmail = JSON.parse(JSON.stringify(email));
      this.invoice.InvoiceSendMethodEmail = true;
      this.invoice.InvoiceSendMethodMail = false;
  }

  setRecieverAddress()
  {
    this.invoice.RecieverCompanyName = this.invoice.Customer.Name;
    this.invoice.RecieverAddress1 = this.invoice.Customer.Address;
    this.invoice.RecieverZip = this.invoice.Customer.Zip;
    this.invoice.RecieverCity = this.invoice.Customer.City;
    this.invoice.RecieverCountry = this.invoice.Customer.Country.Name;
  }

  setCountryName(country: string) {
    this.invoice.Customer.Country.Name = JSON.parse(JSON.stringify(country));
  }

  editCustomer() {

      this.config.data.customer = this.invoice.Customer;
      let dialogRef = this.dialog.open(EditCustomerDialogComponent, this.config);

      dialogRef.afterClosed().subscribe((result: ICompany) => {
          this.updateCustomer(result);
          this.setInvoiceEmail(result.InvoiceEmail);
          dialogRef = null;
      });
  }
  
  getCompanies() {

    

    if (this.currentUserOrganizationId > 0) {
      
      this.organizationService.getCustomers(this.currentUserOrganizationId)
        .subscribe(response => this.companies = response,
          error => this.onGetCompaniesError(error)
        );
        
    }
    else {

      // Get contacts from invoice data if invoice allready exists
      // Get from logged in user if new

      if (this.invoice.User != undefined) {

        this.companyService.getCompanies(this.invoice.User.Id)
          .subscribe(value => this.onGetCompaniesFinished(value),
            error => this.onGetCompaniesError(error)
          );
      }
      else {
        this.companyService.getMyCompanies()
          .subscribe(value => this.onGetCompaniesFinished(value),
            error => this.onGetCompaniesError(error)
          );
      }
    }

  }

  onGetCompaniesError(error: any)
  {
  }

  onGetCompaniesFinished(companies: any)
  {
    this.companies = companies;
  }

  customerChange(id)
  {
      this.invoice.Customer = this.getCustomer(id);
      this.setInvoiceEmail(this.invoice.Customer.InvoiceEmail);
      this.setRecieverAddress();
      this.invoice.Reference.Id = 0;     
  }

  contactChange(contactId: number)
  {
      for (var i = 0; i < this.invoice.Customer.Contacts.length; i++)
      {
          var contact = this.invoice.Customer.Contacts[i];

          if (contactId == contact.Id)
          {
              this.invoice.Reference.Name = contact.Name;
              this.invoice.Reference.Phone = contact.Phone;
              this.invoice.Reference.Email = contact.Email;
              console.log(this.invoice.Reference);
          }
      }
  }

  addSavedContact(contact: IContact, companyId: number)
  {
      this.invoice.Customer.Contacts.push(contact);
      this.invoice.Reference = contact;
  }

  getCustomer(id)
  {
      for (var i = 0; i < this.companies.length; i++)
      {
          if (this.companies[i].Id == this.invoice.Customer.Id)
          {
              console.log(this.companies[i]);
              return JSON.parse(JSON.stringify(this.companies[i]));
          }
      }    
  }

  updateCustomer(company: ICompany) {
      
      for (var i = 0; i < this.companies.length; i++) {

          var currentCompany = this.companies[i];
          
          if (currentCompany.Id == company.Id) {
              this.UpdateCompany(company, currentCompany);
              this.UpdateCompany(company, this.invoice.Customer);
              return;
          }
      }
  }

  addDeduction()
  {
    this.invoice.Deductions.push({ Attachment: { Guid: this.newGuid() }, VatPercentage: "25", Description: '', IncludeInInvoice: "true", TravelExpense: "false" });
  }

  invoiceSendTypeOnChange(sendTypeId: number) {

  }

  UpdateCompany(from: any, to: any)
  {
      to.Name = from.Name;
      to.Address = from.Address;
      to.Address2 = from.Address2;
      to.Co = from.Co;
      to.Zip = from.Zip;
      to.City = from.City;
      to.Phone = from.Phone;
      to.Email = from.Email;
      to.InvoiceEmail = from.InvoiceEmail;
      to.OrgNumber = from.OrgNumber;
      to.Reference = from.Reference;
      to.IsCompany = from.IsCompany;
      to.GlnNumber = from.GlnNumber;
      to.Country = from.Country;
  }

  memberSelected(event: any) {
    this.getUserData(event.option.value);
    this.memberSearchTerm = null;
  }

  filterStates(term: string) {
    this.filteredStates = this.userService.getCompanyUserBySearchTerm(term);
  }

  getUserData(memberNumber: number) {
    this.userService.getCompanyUserBySearchTerm(memberNumber.toString()) 
      .subscribe(response => this.getUserDataFinished(response), error => console.log(error));
  }

  getUserDataFinished(response: any) {

    if (response.length > 0)
      this.selectedMemberObject = response[0];
  }
  
  openLink(link: string) {
    window.open(link, '_blank');
  }

  config = {
      disableClose: false,
      panelClass: 'full-screen-modal',
      hasBackdrop: true,
      backdropClass: '',
      width: '500px',
      maxWidth: '100vw',
      height: '100%',
      position: {
          top: '',
          bottom: '',
          left: '',
          right: ''
      },
      data: {
          invoiceItemGroup: {},
          customerTemplate: {},
        customer: {},
        reference: {},
        contact: { IDNumber: null },
          companyId: 0,
          status: {},
          deduction: {} as IDeduction,
          allowance: {} as IAllowance,
          customerCountryName: null,
      },
      dataTemplate: {
          Id: 0,
          Country: { Id: 1 },
          Type: {},
          HoursWorked: undefined,
          StartDate: undefined,
          EndDate: undefined,
          Comment: undefined,
          InvoiceId: 0
      }
  };

  configSpinner = {
    disableClose: true,
    panelClass: 'full-screen-modal',
    hasBackdrop: true,
    backdropClass: '',
    width: '400px',
    minHeight: '200px',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
  };  

}
export class State {
  constructor(public name: string, public population: string, public flag: string) { }
}
